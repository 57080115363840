import React from 'react'

import { MedicalQuery } from 'services/graphql/medicalService'
import { date } from 'utils'

import { useQuery } from 'urql'

interface CardStatusDataProps {
  dieaseData: {
    adaId: string
    unitThai: string
    indexSelect: string
  }
  puId: string
}

function CardStatusData({ dieaseData, puId }: Readonly<CardStatusDataProps>) {
  const [medicalTrasactionData] = useQuery(
    MedicalQuery.getLatestMedDataTransactionByadaID(dieaseData.adaId, puId),
  )

  const dataMap = JSON.parse(dieaseData.indexSelect)

  const RenderData = () => {
    if (medicalTrasactionData.fetching) return <h1>Loading...</h1>

    if (medicalTrasactionData.error) return <h1>Error</h1>

    if (medicalTrasactionData.data.length === 0)
      return (
        <div className="flex flex-col justify-center items-center gap-2">
          <h1 className="font-bold">ไม่มีข้อมูล</h1>
          <div className="bg-gray-light border border-transparent rounded-xl">
            <h1 className="px-4 text-black-main">-</h1>
          </div>
        </div>
      )

    const data = medicalTrasactionData.data.getMedDataTransactions

    const createDate =
      data.length !== 0
        ? date.convertToShowDateThai(data[0].createdAt)
        : 'ไม่มีข้อมูล'

    const value = data.length !== 0 ? data[0].value.toString() : '-'

    return (
      <div className="flex flex-col justify-center items-center gap-2">
        <h1 className="font-bold">{createDate}</h1>
        <div
          className={`${dataMap[value].bgColor} border border-transparent rounded-xl`}
        >
          <h1 className={`px-4 text-black-main`}>{dataMap[value].word}</h1>
        </div>
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col items-center gap-2">
      <RenderData />
    </div>
  )
}

export default CardStatusData
