import React from 'react'
//import { useQuery } from 'urql'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Pie } from 'react-chartjs-2'
//import { SkeletonComponent } from 'components'

ChartJS.register(
  LinearScale,
  PointElement,
  ArcElement,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
)

function LineChartUser() {
  const preProcessData = () => {
    return {
      labels: [
        'ผู้ป่วยทั้งหมด (คน)',
        'ผู้ป่วยติดตามวันนี้ (คน)',
        'ผู้ป่วยใหม่ (คน)',
      ],
      datasets: [
        {
          //label: 'My First Dataset',
          data: [300, 50, 100],
          backgroundColor: ['#3186C3', '#0F3BEF', '#5BC5EF'],
          hoverOffset: 0,
        },
      ],
    }
  }

  const renderGraph = () => {
    /*if (data.length === 0) {
      return (
        <div className="w-full min-h-[300px] flex justify-center items-center">
          <p className="text-gray-main">ไม่มีข้อมูล</p>
        </div>
      )
    }*/
    const plugin = {
      id: 'customCanvasBackgroundColor',
      beforeDraw: (chart) => {
        const { ctx } = chart

        ctx.save()
        ctx.restore()
      },
    }

    return (
      <div className="h-[270px] w-full">
        <Pie
          plugins={[plugin]}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: 'bottom',
                align: 'start',
              },
            },
          }}
          redraw={true}
          data={preProcessData()}
        />
      </div>
    )
  }

  return (
    <div className="h-full">
      <>
        <div className="flex items-center gap-[5px]">
          <span className="font-bold">ผู้ป่วย</span>
          {/*<span className="text-black-lighter text-[13px]">จำนวนผู้ใช้งาน</span>*/}
        </div>
        <hr className="mt-[10px] mb-[20px]" />
        {renderGraph()}
      </>
    </div>
  )
}

export default LineChartUser
