import { Grid, InputAdornment, TextField } from '@mui/material'
import { Navbar } from 'components/navbar'
import { route } from 'settings'
import { ElementComponent } from 'components'
import { useParams } from 'react-router-dom'
import { PatientQuery } from 'services/graphql/patientService'
import { useQuery } from 'urql'
import { useEffect, useState } from 'react'
function BloodPage() {
  const { patientId } = useParams()
  const { cId } = useParams()
  const [data, setData] = useState([])
  const [patientDiagnoseList] = useQuery(
    PatientQuery.getDiagnoseList(patientId),
  )

  useEffect(() => {
    if (patientDiagnoseList.fetching || !patientDiagnoseList.data) return
    let dataMainItem = []
    patientDiagnoseList.data.getDiagnoseList.map((item: any) => {
      dataMainItem.push({
        date: item.createdAt.split('T')[0],
        imageFile: item.imageFile,
        imageShowData: [],
      })
      //alert(item.imageFile)
    })
    dataMainItem.map((dataItem: any, index: number) => {
      let imageArr = []
      if (dataItem.imageFile !== null) {
        //alert(dataItem.imageFile)
        let items = []
        items = dataItem.imageFile.split(',')
        for (let i = 0; i < items.length; i++) {
          imageArr.push(items[i])
        }
      }
      dataMainItem[index].imageShowData = imageArr
    })
    setData(dataMainItem)
    //alert(JSON.stringify(dataMainItem))
    //JSON.parse(patientDiagnoseLastOne.data.getDiagnoseLastOne.icdData)
  }, [patientDiagnoseList.data])

  return (
    <div className="p-[40px]">
      <Grid
        container
        justifyContent="center"
        columns={{ desktop: 2.2 }}
        gap="8px"
      >
        <Grid item desktop={0.1} className=" p-[8px]" justifyContent="center">
          <Navbar
            activeMenu={route.DIAGNOSES_PAGE_URL}
            patientId={patientId}
            cId={cId}
          />
        </Grid>
        <Grid item desktop={1.9} className="">
          <div className="mb-[24px] flex mr-4">
            <div className="flex gap-[5px] ml-2">
              <p className="text-[22px] text-blue-main ml-2 mt-1">
                รูปผลเลือดวันที่{' '}
              </p>
            </div>
            <div className="flex gap-[5px] ml-2">
              <TextField
                size="small"
                type="date"
                id="outlined-basic"
                variant="outlined"
                placeholder="ค้นหาจาก ชื่อ-นามสกุล"
                InputProps={{
                  sx: { borderRadius: 10 },
                  className: 'bg-zinc-100',
                }}
              />
            </div>
            <p className="text-[22px] text-blue-main ml-2 mt-1">ถึง </p>
            <div className="flex gap-[5px] ml-2">
              <TextField
                size="small"
                type="date"
                id="outlined-basic"
                variant="outlined"
                placeholder="ค้นหาจากเลข HN"
                InputProps={{
                  className: 'bg-zinc-100',
                  sx: { borderRadius: 10 },
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div
            className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
            style={{ overflowY: 'auto' }}
          >
            {data.length > 0 && (
              <>
                {data.map((item: any) => (
                  <>
                    {item.imageShowData.length > 0 && (
                      <>
                        <p className="text-[16px] text-blue-main ml-2 mt-3">
                          {item.date}
                        </p>
                        <Grid
                          container
                          columns={{ desktop: 3.1 }}
                          gap="16px"
                          className="mt-4"
                        >
                          {item.imageShowData.map((itemImg: string) => (
                            <Grid item desktop={1}>
                              <ElementComponent.Image
                                fullImage={true}
                                src={itemImg}
                                className="w-full"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default BloodPage
