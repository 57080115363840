import { Stack, Autocomplete, TextField } from '@mui/material'
import { useState } from 'react'
import { MedicalQuery } from 'services/graphql/medicalService'
import { useQuery } from 'urql'

import * as SVG from 'assets/svg'
interface ItemIcdFormProps {
  index: number
  onRemove?: Function
  onSelected?: Function
}

export default function ItemIcdForm({
  index,
  onRemove,
  onSelected,
}: ItemIcdFormProps) {
  const [open, setOpen] = useState(false)
  const [icd10KeyCode, setIcd10KeyCode] = useState<string>('AAA')
  const [icdApiData] = useQuery(
    MedicalQuery.getIcdTenListWithCode(icd10KeyCode),
  )

  const icd10PreData = []
  if (!icdApiData.fetching && !icdApiData.error) {
    icdApiData.data.getIcdTenList.map((item: any) =>
      icd10PreData.push({
        label: item.icdCode + ' ' + item.icdName,
        icdCode: item.icdCode,
        icdName: item.icdName,
      }),
    )
  }

  const onTextConsultChange = (event) => {
    if (event.target.value.length > 3) {
      setIcd10KeyCode(event.target.value)
    }
  }
  return (
    <Stack
      direction={{ laptop: 'row' }}
      justifyContent="space-between"
      alignItems="center"
    >
      {!icdApiData.fetching && !icdApiData.error && (
        //alert(JSON.stringify())
        <>
          <Autocomplete
            open={open}
            onInputChange={(_, value) => {
              if (value.length === 0) {
                if (open) setOpen(false)
              } else {
                if (!open) setOpen(true)
              }
            }}
            onChange={(_, value) => onSelected(value)}
            onClose={() => setOpen(false)}
            disablePortal
            id={'combo-box-demo-' + index}
            className="w-[94%]"
            options={icd10PreData}
            noOptionsText="ไม่มีข้อมูล พิมพ์รหัสเพื่อค้นหา"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                onChange={onTextConsultChange}
              />
            )}
          />
          <div onClick={() => onRemove()}>
            <SVG.BtnDelete />
          </div>
        </>
      )}
    </Stack>
  )
}
