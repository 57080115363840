import React from 'react'
import { useRoutes, Navigate, BrowserRouter } from 'react-router-dom'

import { NavComponent } from 'components'
import { route } from 'settings'
import { general } from 'utils'
import {
  LoginPages,
  ProfilePages,
  OverviewPages,
  DiagnosePages,
  PatientPages,
} from 'pages'

function RoutePage() {
  const auth = general.auth.isAuthorized()

  const redirectCondition = (condition: boolean, children: React.ReactNode) => {
    if (condition) return children

    if (!auth) return <Navigate replace to={route.LOGIN_PAGE_URL} />
    else return <Navigate replace to={route.OVERVIEW_PAGE_URL} />
  }

  return useRoutes([
    {
      path: '/',
      element: <NavComponent.MainLayout />,
      children: [
        {
          path: '/',
          element: redirectCondition(auth, <OverviewPages.DashboardPage />),
        },
        {
          path: route.LOGIN_PAGE_URL,
          element: redirectCondition(!auth, <LoginPages.Login />),
        },
        {
          path: route.OVERVIEW_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.DashboardPage />),
        },
        {
          path: route.DIAGNOSE_PAGE_URL,
          element: redirectCondition(auth, <DiagnosePages.DiagnosePage />),
        },
        {
          path: route.DIAGNOSE_DATA_PAGE_URL,
          element: redirectCondition(auth, <DiagnosePages.DiagnoseDataPage />),
        },
        {
          path: route.PATIENT_PAGE_URL,
          element: redirectCondition(auth, <PatientPages.PatientPage />),
        },
        {
          path: route.PATIENT_DATA_PAGE_URL,
          element: redirectCondition(auth, <PatientPages.PatientDataPage />),
        },
        {
          path: route.OVERAll_DATA_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.OverAllPage />),
        },
        {
          path: route.DISEASE_DATA_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.DiseasePage />),
        },
        {
          path: route.OVERAll_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.OverAllPage />),
        },
        {
          path: route.DISEASE_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.DiseasePage />),
        },
        {
          path: route.DIAGNOSES_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.BloodPage />),
        },
        {
          path: route.DIAGNOSES_DATA_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.BloodPage />),
        },
        {
          path: route.MEDICAL_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.MedicalPage />),
        },
        {
          path: route.MEDICAL_DATA_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.MedicalPage />),
        },
        {
          path: route.INPUT_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.InputPage />),
        },
        {
          path: route.INPUT_DATA_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.InputPage />),
        },
        {
          path: route.SETTING_PAGE_URL,
          element: <NavComponent.UserLayout />,
          children: [
            {
              path: route.SETTING_PAGE_URL,
              element: <Navigate to={route.PROFILE_PAGE_URL} />,
            },
            {
              path: route.PROFILE_PAGE_URL,
              element: redirectCondition(
                auth,
                <ProfilePages.UserProfilePage />,
              ),
            },
            {
              path: route.CHANGE_PASSWORD_PAGE_URL,
              element: redirectCondition(
                auth,
                <ProfilePages.ChangePasswordPage />,
              ),
            },
          ],
        },
      ],
    },
    {
      path: route.EXPECT_PAGE_URL,
      element: <Navigate to={route.LOGIN_PAGE_URL} />,
    },
  ])
}

const Router = () => {
  return (
    <BrowserRouter basename={'/'}>
      <RoutePage />
    </BrowserRouter>
  )
}

export default Router
