import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import { Link } from 'react-router-dom'
import { Box, Tabs, Tab, Tooltip, Pagination } from '@mui/material'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'
import { useRecoilState } from 'recoil'

import { MedicalQuery } from 'services/graphql/medicalService'
import { ElementComponent, SkeletonComponent } from 'components'
import { useSystem } from 'stores/recoil'
import { route } from 'settings'
import { date } from 'utils'

type TablesStatus = 'DIAGNOSE' | 'NOT_DIAGNOSE'

const topicTableNotDiagnose = [
  'HN',
  'ชื่อ-นามสกุล',
  'วันที่คนไข้ทำการส่งตรวจ',
  'ตัวเลือก',
]
const topicTableDiagnose = [
  'HN',
  'ชื่อ-นามสกุล',
  'วันที่คนไข้ทำการส่งตรวจ',
  'วันที่ทำการตรวจ',
  'วันที่ทำการแก้ไขข้อมูล',
  'ตัวเลือก',
]

export default function DiagnosePage() {
  const [tables, setTables] = useRecoilState(
    useSystem.selector.selectorDiagnosisTabStateAtom,
  )
  const [searchTable, setSearchTable] = useState<string>('')
  const [pageAmount, setPageAmount] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const pageSize = 10

  const [medicalTableData, setMedicalTableData] = useState<any[]>([])
  const [medicalDataTotalData] = useQuery({
    ...MedicalQuery.getMedTransactionPagingCount({
      dataInputFlag: { equals: true },
      diagnosisData: {
        ...(tables === 'NOT_DIAGNOSE'
          ? { none: {} }
          : {
              some: {
                doctorUser: {
                  is: {
                    uId: {
                      equals: '',
                    },
                  },
                },
              },
            }),
      },
      ...(searchTable
        ? {
            patientUser: {
              is: {
                hnId: {
                  equals: parseInt(searchTable),
                },
              },
            },
          }
        : {}),
    }),
  })

  const [medicalData] = useQuery({
    ...MedicalQuery.getMedTransaction(
      {
        dataInputFlag: { equals: true },
        diagnosisData: {
          ...(tables === 'NOT_DIAGNOSE'
            ? { none: {} }
            : {
                some: {
                  doctorUser: {
                    is: {
                      uId: {
                        equals: '',
                      },
                    },
                  },
                },
              }),
        },
        ...(searchTable
          ? {
              patientUser: {
                is: {
                  hnId: {
                    equals: parseInt(searchTable),
                  },
                },
              },
            }
          : {}),
      },
      pageSize,
      (page - 1) * pageSize,
      tables === 'NOT_DIAGNOSE',
    ),
  })

  const handleChangeTable = (value: TablesStatus) => {
    setTables(value)
    setSearchTable('')
    setPage(1)
  }

  const option = (
    diagnoseId: string,
    allowed_to_update: boolean,
    not_diagnose: boolean,
  ) => {
    const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#C9E2F4',
        color: '#1475BB',
        fontSize: 12,
      },
    })

    let tooltip_tile
    if (not_diagnose) {
      tooltip_tile = 'วินิจฉัยข้อมูล'
      return (
        <div className="flex justify-center">
          <StyleToolTip title={tooltip_tile} color={'info'}>
            <Link to={`${route.DIAGNOSE_PAGE_URL}/${diagnoseId}`}>
              <Icon
                color="#353535"
                icon="solar:document-bold"
                className="w-[28px] h-[28px] hover:text-blue-main cursor-pointer"
              />
            </Link>
          </StyleToolTip>
        </div>
      )
    }
    if (allowed_to_update && !not_diagnose) tooltip_tile = 'แก้ไขข้อมูล'

    return (
      <div className="flex justify-center">
        <StyleToolTip title={tooltip_tile} color={'info'}>
          <Link
            to={
              allowed_to_update
                ? `${route.DIAGNOSE_PAGE_URL}/${diagnoseId}/?editor=true}`
                : '#'
            }
          >
            <Icon
              color={allowed_to_update ? '#353535' : '#00000050'}
              icon="solar:document-bold"
              className={`w-[28px] h-[28px] cursor-pointer hover:${
                allowed_to_update ? 'text-blue-main' : 'text-gray-400'
              } ${allowed_to_update ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            />
          </Link>
        </StyleToolTip>
      </div>
    )
  }

  const formatData = (data, not_diagnose: boolean) => {
    if (not_diagnose)
      return data.map((item) => ({
        [topicTableNotDiagnose[0]]: `${Array(
          7 - item.patientUser?.hnId?.toString()?.length,
        )
          .fill(0)
          .join('')}${item.patientUser?.hnId}`,
        [topicTableNotDiagnose[1]]: `${item.patientUser?.firstname} ${item.patientUser?.lastname}`,
        [topicTableNotDiagnose[2]]: date.convertToShowDateTime(item.createdAt),
        [topicTableNotDiagnose[3]]: option(item.umdId, true, not_diagnose),
      }))

    return data.map((item) => ({
      [topicTableDiagnose[0]]: `${Array(
        7 - item.patientUser?.hnId?.toString()?.length,
      )
        .fill(0)
        .join('')}${item.patientUser?.hnId}`,
      [topicTableDiagnose[1]]: `${item.patientUser?.firstname} ${item.patientUser?.lastname}`,
      [topicTableDiagnose[2]]: date.convertToShowDateTime(item.createdAt),
      [topicTableDiagnose[3]]: date.convertToShowDateTime(
        item.diagnosisData[0].createdAt,
      ),
      [topicTableDiagnose[4]]: date.convertToShowDateTime(
        item.diagnosisData[0].updatedAt,
      ),
      [topicTableDiagnose[5]]: option(
        item.umdId,
        item.diagnosisData[0].allowedUpdateFlag,
        not_diagnose,
      ),
    }))
  }

  const handleSearchData = () => {
    setPage(1)
  }

  useEffect(() => {
    if (
      medicalData.fetching ||
      !medicalData.data ||
      medicalDataTotalData.fetching ||
      !medicalDataTotalData.data
    )
      return

    setPageAmount(
      Math.ceil(
        medicalDataTotalData.data.getAggregateMedicalData._count.umdId / 10,
      ),
    )

    const data = formatData(
      medicalData.data.getMedicalDatas,
      tables === 'NOT_DIAGNOSE',
    )

    setMedicalTableData(data)
  }, [medicalData.data, medicalDataTotalData.data])

  useEffect(() => {
    setPage(1)
  }, [searchTable])

  return (
    <div className="p-[80px]">
      <div className="flex items-center gap-[10px]">
        <Icon
          icon="solar:document-medicine-bold"
          className="w-[28px] h-[28px]"
        />
        <p className="text-[24px]">วินิจฉัยโรค</p>
      </div>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: '40px',
          marginBottom: '25px',
        }}
      >
        <Tabs
          value={tables}
          onChange={(_, value: TablesStatus) => handleChangeTable(value)}
        >
          <Tab
            label="งานที่ยังไม่ได้วินิจฉัยโรค"
            value="NOT_DIAGNOSE"
            sx={{ fontSize: '18px' }}
          />
          <Tab
            label="งานที่วินิจฉัยโรคแล้ว"
            value="DIAGNOSE"
            sx={{ fontSize: '18px' }}
          />
        </Tabs>
      </Box>

      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[8px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="mb-[24px] flex justify-end">
          <Formik
            initialValues={{ text: '' }}
            onSubmit={() => handleSearchData()}
          >
            {(formik) => (
              <Form>
                <div className="flex gap-[5px]">
                  <ElementComponent.InputBar
                    id="search-data-table"
                    name="text"
                    focusSpacing={false}
                    isLoading={false}
                    placeholder="ค้นหา HN"
                    height="40px"
                    frontIcon={
                      <Icon
                        icon="material-symbols:search-rounded"
                        className="w-[20px] h-[20px]"
                      />
                    }
                    value={formik.values.text}
                    onChange={(text: string) => {
                      formik.setFieldValue('text', text)
                      setSearchTable(text)
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {!medicalData?.fetching || !medicalDataTotalData.fetching ? (
          <div>
            <ElementComponent.Table
              col={
                tables === 'NOT_DIAGNOSE'
                  ? topicTableNotDiagnose
                  : topicTableDiagnose
              }
              row={medicalTableData}
            />
            {medicalTableData.length === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลคนไข้
                </p>
              </div>
            )}
          </div>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={4} />
        )}

        {pageAmount !== 0 && (
          <div className="mt-[30px] flex justify-center">
            <Pagination
              page={page}
              count={pageAmount}
              variant="outlined"
              shape="rounded"
              onChange={(_, newPage: number) => setPage(newPage)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
