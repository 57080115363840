import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'urql'
import { Formik, Form } from 'formik'
import { Pagination } from '@mui/material'
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'

import { ElementComponent, SkeletonComponent } from 'components'
import { date } from 'utils'
import { route } from 'settings'
import { PatientQuery } from 'services/graphql/patientService'

const topicTable = [
  'HN',
  'ชื่อ-นามสกุล',
  'อายุ',
  'อำเภอ/เขต',
  'จังหวัด',
  'ตัวเลือก',
]

const option = (userId: string) => {
  const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#C9E2F4',
      color: '#1475BB',
      fontSize: 12,
    },
  })

  return (
    <div className="flex justify-center">
      <StyleToolTip title="แสดงข้อมูล" color="info">
        <Link to={`${route.PATIENT_PAGE_URL}/${userId}`}>
          <Icon
            icon="solar:document-bold"
            className="w-[28px] h-[28px] cursor-pointer hover:text-blue-main"
          />
        </Link>
      </StyleToolTip>
    </div>
  )
}

export default function PatientPage() {
  const [page, setPage] = useState<number>(1)
  const [pageAmount, setPageAmount] = useState<number>(1)
  const [searchTable, setSearchTable] = useState<string>('')
  const pageSize = 10

  const [patientCount] = useQuery(PatientQuery.getPatientCount())
  const [patientList] = useQuery(
    PatientQuery.getPatientList(
      {
        ...(searchTable
          ? {
              hnId: {
                equals: parseInt(searchTable),
              },
            }
          : {}),
      },
      pageSize,
      (page - 1) * pageSize,
    ),
  )

  const fotmatTableData = (data: any) => {
    return data.map((item: any) => ({
      [topicTable[0]]: `${Array(7 - item?.hnId?.toString()?.length ?? 0)
        .fill(0)
        .join('')}${item.hnId}`,
      [topicTable[1]]: `${item.firstname} ${item.lastname}`,
      [topicTable[2]]: date.getAge(item?.UserMedicalDemograhicData?.birthdate),
      [topicTable[3]]: item?.UserMedicalDemograhicData?.district,
      [topicTable[4]]: item?.UserMedicalDemograhicData?.province,
      [topicTable[5]]: option(item.uId),
    }))
  }

  const handleChangeSearch = (value: string) => {
    setSearchTable(value)
    setPage(1)
  }

  useEffect(() => {
    if (patientCount.fetching || !patientCount.data) return

    const { aggregateUser } = patientCount.data
    setPageAmount(Math.ceil(aggregateUser._count.uId / 10))
  }, [patientCount.data])

  return (
    <div className="p-[80px]">
      <div className="flex items-center gap-[10px]">
        <Icon
          icon="solar:document-medicine-bold"
          className="w-[28px] h-[28px]"
        />
        <p className="text-[24px]">ข้อมูลคนไข้</p>
      </div>

      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[8px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="mb-[24px] flex justify-end">
          <Formik initialValues={{ text: '' }} onSubmit={null}>
            {(formik) => (
              <Form>
                <div className="flex gap-[5px]">
                  <ElementComponent.InputBar
                    id="search-data-table"
                    name="text"
                    focusSpacing={false}
                    isLoading={false}
                    placeholder="ค้นหา HN"
                    height="40px"
                    frontIcon={
                      <Icon
                        icon="material-symbols:search-rounded"
                        className="w-[20px] h-[20px]"
                      />
                    }
                    value={formik.values.text}
                    onChange={(text: string) => {
                      formik.setFieldValue('text', text)
                      handleChangeSearch(text)
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {!patientList.fetching ? (
          <div>
            <ElementComponent.Table
              col={topicTable}
              row={fotmatTableData(patientList?.data?.getUsers)}
            />
            {patientList.data?.getUsers.length === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลคนไข้
                </p>
              </div>
            )}
          </div>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={6} />
        )}

        {pageAmount !== 0 && patientList.data?.getUsers.length !== 0 && (
          <div className="mt-[30px] flex justify-center">
            <Pagination
              page={page}
              count={pageAmount}
              variant="outlined"
              shape="rounded"
              onChange={(_, newPage: number) => setPage(newPage)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
