/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Checkbox, Divider, Fab, Grid, Stack } from '@mui/material'
import { Icon } from '@iconify/react'

import { ElementComponent } from 'components'
import dayjs from 'dayjs'
import { useQuery } from 'urql'
import { PatientQuery } from 'services/graphql/patientService'

interface DiagnoseModalProps {
  puId: string
  open: boolean
  onClose: Function
  canChooseDrug?: boolean
  onChooseDrug?: Function
}

export default function DiagnoseModal({
  puId,
  open,
  onClose,
  canChooseDrug = false,
  onChooseDrug,
}: DiagnoseModalProps) {
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false)
  const [indexItem, setIndexItem] = useState<number>(0)
  const [patientDiagnoseLastOne] = useQuery(PatientQuery.getDiagnoseList(puId))
  return (
    <ElementComponent.ModalForm open={open} onClose={onClose}>
      <div className="w-[980px] py-[40px] px-[25px]">
        {!patientDiagnoseLastOne.fetching &&
        !patientDiagnoseLastOne.error &&
        patientDiagnoseLastOne.data.getDiagnoseList.length > 0 ? (
          <Grid
            container
            justifyContent="center"
            columns={{ desktop: 2.05 }}
            gap="8px"
          >
            <Grid item desktop={0.5}>
              <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                วันที่ตรวจ
              </p>
            </Grid>
            <Grid item desktop={1.5}>
              <Stack
                direction={{ laptop: 'row' }}
                justifyContent="space-between"
                alignItems="center"
                className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[260px] p-[4px] text-center"
              >
                {patientDiagnoseLastOne.data.getDiagnoseList.length > 1 && (
                  <Fab
                    color="default"
                    aria-label="add"
                    size="small"
                    onClick={() => {
                      if (
                        patientDiagnoseLastOne.data.getDiagnoseList.length - 1 < // 2
                        indexItem
                      )
                        setIndexItem(indexItem - 1)
                      else
                        setIndexItem(
                          patientDiagnoseLastOne.data.getDiagnoseList.length -
                            1,
                        )
                    }}
                  >
                    {'<'}
                  </Fab>
                )}

                <p>{`${dayjs(
                  patientDiagnoseLastOne.data.getDiagnoseList[
                    indexItem
                  ].createdAt.split('T')[0],
                ).format('DD MMMM BBBB')}`}</p>
                {patientDiagnoseLastOne.data.getDiagnoseList.length > 1 && (
                  <Fab
                    color="default"
                    aria-label="add"
                    size="small"
                    onClick={() => {
                      if (
                        patientDiagnoseLastOne.data.getDiagnoseList.length - 1 > // 2
                        indexItem
                      )
                        setIndexItem(indexItem + 1)
                      else setIndexItem(0)
                    }}
                  >
                    {'>'}
                  </Fab>
                )}
              </Stack>
            </Grid>
            <Grid item desktop={0.5}>
              <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                แพทย์ผู้ตรวจ
              </p>
            </Grid>
            <Grid item desktop={1.5}>
              <p className="text-[16px] w-[200px] p-[4px]">นพ. ธนกร ยนต์นิยม</p>
            </Grid>
            <Grid item desktop={0.5}>
              <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[180px] p-[4px] text-center">
                รายละเอียดการตรวจ
              </p>
            </Grid>
            <Grid item desktop={1.5}>
              <div
                className="text-[16px] rounded-[5px] p-[10px]"
                style={{
                  borderColor: '#ccc',
                  borderWidth: '1px',
                }}
              >
                <p>
                  {
                    patientDiagnoseLastOne.data.getDiagnoseList[indexItem]
                      .detail
                  }
                </p>
              </div>
            </Grid>

            <Grid item desktop={0.5}>
              <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[100px] p-[4px] text-center">
                คำเเนะนำ
              </p>
            </Grid>
            <Grid item desktop={1.5}>
              <div
                className="text-[16px] rounded-[5px] p-[10px]"
                style={{
                  borderColor: '#ccc',
                  borderWidth: '1px',
                }}
              >
                <p>
                  {
                    patientDiagnoseLastOne.data.getDiagnoseList[indexItem]
                      .recommend
                  }
                </p>
              </div>
            </Grid>
            <Grid item desktop={0.5}>
              <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[100px] p-[4px] text-center">
                คำวินิจฉัย
              </p>
            </Grid>
            <Grid item desktop={1.5}>
              <div
                className="text-[16px] rounded-[5px] p-[10px]"
                style={{
                  borderColor: '#ccc',
                  borderWidth: '1px',
                }}
              >
                <Grid container columns={{ desktop: 2.1 }} gap="8px">
                  {JSON.parse(
                    patientDiagnoseLastOne.data.getDiagnoseList[indexItem]
                      .icdData,
                  ).map((item: any) => (
                    <>
                      <Grid item desktop={0.3}>
                        <span>{item.icdCode}</span>
                      </Grid>
                      <Grid item desktop={1.7}>
                        <span>{item.icdName}</span>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
            </Grid>

            <Grid item desktop={0.5}>
              <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[100px] p-[4px] text-center">
                ยาที่ได้รับ
              </p>
            </Grid>
            <Grid item desktop={1.5}>
              {canChooseDrug && (
                <span>
                  <Checkbox
                    size="small"
                    className="w-[20px] h-[20px]"
                    onChange={(event) => {
                      setIsSelectedAll(event.target.checked)
                    }}
                  />
                  เลือกทั้งหมด
                </span>
              )}
              <div
                className="text-[16px] rounded-[5px] p-[10px]"
                style={{
                  borderColor: '#ccc',
                  borderWidth: '1px',
                }}
              >
                <Grid container columns={{ desktop: 3.1 }} gap="8px">
                  {JSON.parse(
                    patientDiagnoseLastOne.data.getDiagnoseList[indexItem]
                      .drugData,
                  ).map((item: any, index: number) => (
                    <>
                      <Grid item desktop={1.0}>
                        <span>
                          {canChooseDrug && (
                            <Checkbox
                              size="small"
                              className="w-[20px] h-[20px]"
                              checked={isSelectedAll ? true : false}
                            />
                          )}
                          {index + 1}.{item.dName} {item.quantity} {item.unit}
                        </span>
                      </Grid>

                      {item.dUseType === 'ยาฉีด' ? (
                        <>
                          <Grid item desktop={1.5}>
                            <span>
                              {item.injQtyVal} {item.injUnit}
                            </span>
                          </Grid>
                          <Grid item desktop={0.5}>
                            <span>{item.qty} </span> เข็ม
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item desktop={1.5}>
                            <span>{item.dutName}</span>
                          </Grid>
                          <Grid item desktop={0.5}>
                            <span>{item.qty} </span> เม็ด
                          </Grid>
                        </>
                      )}
                    </>
                  ))}
                </Grid>
              </div>
              {canChooseDrug && (
                <p
                  className="text-[16px] text-white-main  bg-green-main rounded-[20px] w-[120px] p-[4px] text-center mt-2"
                  onClick={() => {
                    onChooseDrug(
                      JSON.parse(
                        patientDiagnoseLastOne.data.getDiagnoseList[indexItem]
                          .drugData,
                      ),
                    )
                    onClose(true)
                  }}
                >
                  ยืนยัน
                </p>
              )}
            </Grid>
          </Grid>
        ) : (
          <p>ไม่มีข้อมูล</p>
        )}

        {/*<div className="flex flex-row items-center gap-[10px]">
          <Icon
            icon="solar:document-medicine-bold"
            className="w-[35px] h-[35px]"
          />
          <p className="font-bold text-[24px]">ประวัติการวินิจฉัย</p>
        </div>
        <Divider sx={{ marginY: '20px' }} />

        <div className="mt-[15px]">
          <div className="mt-[28px] flex flex-col gap-[20px]">
            <p className="text-[20px] font-bold">ผลลัพธ์การวินิจฉัย</p>
            <p className="text-[16px]">-</p>
          </div>
          <div className="mt-[28px] flex flex-col gap-[20px]">
            <p className="text-[20px] font-bold">
              รายละเอียดการวินิจฉัยเพิ่มเติม
            </p>
            <p className="text-[16px]">-</p>
          </div>
        </div>*/}
      </div>
    </ElementComponent.ModalForm>
  )
}
