import React from 'react'

import { LoginForm } from 'components/form'
import { LoginSVG, Logo2SVG } from 'assets/svg'

function LoginPage() {
  return (
    <div className="flex h-screen">
      <div
        style={{
          background:
            'linear-gradient(89.95deg, #004F64 0.05%, #004884 104.41%)',
        }}
        className="w-1/2 text-white-main text-center flex flex-col justify-center items-center"
      >
        <div className="mb-[80px] scale-[2]">
          <Logo2SVG />
        </div>
        <LoginSVG />
        <p className="text-[28px] mt-[60px] p-[20px]">
          DR. ASA PRIMARY CARE PLATFORM
        </p>
        <p className="mx-[130px]">
          “จะรอให้ตัวเองป่วยทำไม ในเมื่อคุณป้องกันโรคของตัวเองได้
          มาออกแบบสุขภาพตัวเองกับหมอประจำตัวด้วย Dr. ASA”
        </p>
      </div>

      <div className="w-1/2 text-center p-[57px] bg-white-dark">
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage
