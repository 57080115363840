export const createDiagnoseResult = `
  mutation createDiagnosisData ($data: DiagnosisDataCreateCustomInput!) {
    createDiagnosisData (data: $data) {
        ddId
    }
  }
`

export const updateDiagnoseResult = `
  mutation updateDiagnosisData($data: DiagnosisDataUpdateCustomInput!, $where: DiagnosisDataWhereUniqueInput!) {
    updateDiagnosisData(data: $data, where: $where){
      ddId
    }
}`

export const updateUserInfo = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
        uId
    }
  }
`
export const createDiagnose = `
  mutation createDiagnose ($data: DiagnoseCreateInput!, $files: [Upload!]) {
    createDiagnose (data: $data, files: $files) {
        dId,
    }
  }
`

export const createConsult = `
mutation createConsultForDoctor ($data: ConsultCreateInput!) {
  createConsultForDoctor (data: $data) {
      ctId,
  }
}
`
export const updateConsult = `
mutation updateConsult ($data: ConsultUpdateInput!, $where: ConsultWhereUniqueInput!) {
  updateConsult (data: $data, where: $where) {
      cId
  }
}
`
