import React, { useEffect, useState } from 'react'
import { Divider, Grid, Skeleton } from '@mui/material'
import { useQuery, useMutation } from 'urql'
import { useParams, useSearchParams } from 'react-router-dom'
import { Icon } from '@iconify/react'

import { MenuComponent, CardComponent, SkeletonComponent } from 'components'
import { MedicalQuery, MedicalMutate } from 'services/graphql/medicalService'
import { route } from 'settings'
import { general } from 'utils'

const menuList = [{ title: 'วินิจฉัยโรค', link: route.DIAGNOSE_PAGE_URL }]

const userDiseaseInitial = {
  underlying: [],
  specialCase: '',
}

export default function DiagnoseDataPage() {
  const { diagnoseId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const [patientData, setPatientData] = useState<any>({})
  const [lastDiagnose, setLastDianose] = useState<any>({})
  const [userDisease, setUserDisease] = useState<any>(userDiseaseInitial)

  const [patientMedicalData] = useQuery(
    MedicalQuery.getMedicalDatasPage(diagnoseId),
  )

  // -> Execute function for update user diagenosis.
  const [updateUserInfo, executeUserDisease] = useMutation(
    MedicalMutate.updateUserInfo,
  )
  const [diagnoseResult, executeDiagnoseResult] = useMutation(
    MedicalMutate.createDiagnoseResult,
  )
  const [updateDiagnoseResult, executeUpdateDiagnoseResult] = useMutation(
    MedicalMutate.updateDiagnoseResult,
  )

  // -> Function handle data for check create new or delete that.
  const formatUserDiseaseSubmit = (
    originData: any,
    newData: any,
  ): { created: string[]; deleted: string[] } => {
    const createList: string[] = []
    const deleteList: string[] = []

    if (newData.length) {
      for (const dataItem of newData) {
        if (
          !originData.some(
            (originItem: any) =>
              originItem.underlyingDisease.udId === dataItem.key,
          )
        )
          createList.push(dataItem.key)
      }

      for (const dataItem of originData) {
        if (
          !newData.some(
            (newItem: any) => newItem.key === dataItem.underlyingDisease.udId,
          )
        )
          deleteList.push(dataItem.uudtId)
      }
    }

    return {
      created: createList,
      deleted: deleteList,
    }
  }

  const handleTitlePage = (list: any[]) => {
    return [
      ...list,
      {
        title:
          searchParams.get('editor') === 'true'
            ? 'งานที่วินิจฉัยแล้ว'
            : 'งานที่ยังไม่ได้วินิจฉัย',
        link: '',
      },
    ]
  }

  const handleSubmitDiagnoseForm = async (data: any) => {
    if (searchParams.get('editor') === 'true') await updateDiagnose(data)
    else await createDiagnose(data)

    // -> Execute part for update user Diagnosis.
    const { created, deleted } = formatUserDiseaseSubmit(
      patientData?.patientUser?.UserMedicalDemograhicData
        ?.userUnderlyingDiseaseTrasaction,
      userDisease.underlying,
    )

    if (
      created.length > 0 ||
      deleted.length > 0 ||
      userDisease.specialCase !== ''
    )
      await executeUserDisease({
        data: {
          UserMedicalDemograhicData: {
            update: {
              data: {
                allergic: {
                  set: userDisease.specialCase,
                },
                ...(created.length > 0 || deleted.length > 0
                  ? {
                      userUnderlyingDiseaseTrasaction: {
                        ...(created.length
                          ? {
                              create: created.map((item: any) => ({
                                underlyingDisease: {
                                  connect: {
                                    udId: item,
                                  },
                                },
                              })),
                            }
                          : {}),
                        ...(deleted.length
                          ? {
                              deleteMany: deleted.map((item: any) => ({
                                uudtId: {
                                  equals: item,
                                },
                              })),
                            }
                          : {}),
                      },
                    }
                  : {}),
              },
            },
          },
        },
        where: {
          uId: patientData?.patientUser?.uId,
        },
      })

    if (searchParams.get('editor') !== 'true') general.http.goto('/diagnose')
  }

  const updateDiagnose = async (data: any) => {
    await executeUpdateDiagnoseResult({
      data: {
        diagnosisResult: {
          set: data.diagnosisResult,
        },
        description: {
          set: data.description,
        },
        suggestion: {
          set: data.suggestion,
        },
      },
      where: {
        ddId: lastDiagnose.ddId,
      },
    })
  }

  const createDiagnose = async (data: any) => {
    await executeDiagnoseResult({
      data: {
        allowedUpdateFlag: true,
        userMedicalData: {
          connect: {
            umdId: patientData?.umdId,
          },
        },
        patientUser: {
          connect: {
            uId: patientData?.patientUser?.uId,
          },
        },
        ...data,
      },
    })
  }

  useEffect(() => {
    if (patientMedicalData.fetching || !patientMedicalData.data) return

    const { getMedicalData } = patientMedicalData.data
    setPatientData(getMedicalData)

    if (getMedicalData.diagnosisData.length > 0) {
      setLastDianose(getMedicalData.diagnosisData[0])
      setSearchParams({ editor: 'true' }, { replace: true })
      return
    }

    setSearchParams({}, { replace: true })
  }, [patientMedicalData.data])

  return (
    <div className="p-[40px]">
      <MenuComponent.MenuHeaderBar menuList={handleTitlePage(menuList)} />
      {!patientMedicalData.fetching && !patientMedicalData.error ? (
        <div className="flex items-center gap-[10px] mt-[25px]">
          <Icon
            icon="solar:document-medicine-bold"
            className="w-[35px] h-[35px]"
          />
          <p className="text-[24px]">
            {`ข้อมูล ${patientData?.patientUser?.prefix} ${patientData?.patientUser?.firstname} ${patientData?.patientUser?.lastname}`}
          </p>
        </div>
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{ marginTop: '25px' }}
          height="50px"
          width="50%"
        />
      )}

      <Divider sx={{ marginY: '30px' }} />

      <Grid container columnSpacing="20px">
        {!patientMedicalData.fetching && !patientMedicalData.error ? (
          <Grid item mobile>
            <CardComponent.MedicalPatientCard
              isLoading={
                updateDiagnoseResult.fetching ||
                updateUserInfo.fetching ||
                diagnoseResult.fetching
              }
              onChangeValue={setUserDisease}
              hnId={patientData?.patientUser?.hnId ?? ''}
              gender={
                patientData?.patientUser?.UserMedicalDemograhicData?.gender
              }
              age={
                patientData?.patientUser?.UserMedicalDemograhicData?.birthdate
              }
              allergic={
                patientData?.patientUser?.UserMedicalDemograhicData?.allergic
              }
              underlying={
                patientData?.patientUser?.UserMedicalDemograhicData
                  ?.userUnderlyingDiseaseTrasaction
              }
            />
            <CardComponent.MedicalTransection
              transaction={patientData?.userMedTrasaction}
              puId={patientData?.puId}
            />
          </Grid>
        ) : (
          <Grid item mobile>
            <SkeletonComponent.HealthDataCradSkeleton />
            <SkeletonComponent.MedicalPatientCardSkeleton />
          </Grid>
        )}

        {!patientMedicalData.fetching && !patientMedicalData.error ? (
          <Grid item mobile>
            <CardComponent.HistoryDiagnoseCard
              puId={patientData.puId}
              ddId={lastDiagnose?.ddId}
            />
            <CardComponent.DiagnoseDataPage
              isLoading={
                updateDiagnoseResult.fetching ||
                updateUserInfo.fetching ||
                diagnoseResult.fetching
              }
              submitDiagnoseResuslt={handleSubmitDiagnoseForm}
              lastSubmitData={lastDiagnose}
            />
          </Grid>
        ) : (
          <Grid item mobile>
            <SkeletonComponent.HistoryDiagnoseCardSkeleton />
            <SkeletonComponent.DiagnosePatientCardSkeleton />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
