import { Grid, InputAdornment, Stack, TextField, Rating } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  AvatarProfile,
  IcoCorrect,
  IcoEdit,
  IcoOnline,
  IcoPhp,
  IcoWrong,
} from 'assets/svg'
import { Icon } from '@iconify/react'
import { ChartComponent, ModalComponent } from 'components'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { route } from 'settings'
import { PatientQuery } from 'services/graphql/patientService'
import { useQuery } from 'urql'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { useUser } from 'stores/recoil'
type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

function DaskboardPage() {
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [dateValue, onDateChange] = useState<Value>(new Date())

  const [patientConsultOnlineList] = useQuery(
    PatientQuery.getPatientConsultList(
      dayjs(dateValue.toString()).format('DD/MM/YYYY'),
      'online',
      user['uId'],
    ),
  )
  const [patientConsultWalkinList] = useQuery(
    PatientQuery.getPatientConsultList(
      dayjs(dateValue.toString()).format('DD/MM/YYYY'),
      'walkin',
      user['uId'],
    ),
  )
  const [data, setData] = useState({})
  const [openModal, setOpenModal] = useState<boolean>(false)
  return (
    <div className="p-[40px]">
      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[20px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Stack direction={{ mobile: 'column', laptop: 'column' }}>
          <div className="mb-[24px] flex justify-end mr-4">
            <div className="flex gap-[5px]">
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                placeholder="ค้นหาจาก ชื่อ-นามสกุล"
                InputProps={{
                  sx: { borderRadius: 10 },
                  className: 'bg-zinc-100',
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        icon="material-symbols:search-rounded"
                        className="w-[20px] h-[20px]"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flex gap-[5px] ml-2">
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                placeholder="ค้นหาจากเลข HN"
                InputProps={{
                  className: 'bg-zinc-100',
                  sx: { borderRadius: 10 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        icon="material-symbols:search-rounded"
                        className="w-[20px] h-[20px]"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flex gap-[5px] ml-2">
              <p className="text-[22px] text-blue-main ml-2 mt-1">สวัสดี</p>
            </div>
            <div className="flex gap-[5px] ml-2 mt-1">
              <p className="text-[20px] ml-2">{user['firstname']}</p>
            </div>
            <div className="flex gap-[5px] ml-2">
              <AvatarProfile className="h-[40px] w-[40px] rounded-full bg-cover bg-center" />
            </div>
          </div>

          <Grid
            container
            justifyContent="center"
            columns={{ desktop: 3.3 }}
            gap="8px"
          >
            <Grid
              item
              desktop={1.6}
              className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
            >
              <ChartComponent.LineChartUserOverview />
            </Grid>
            <Grid
              item
              desktop={0.6}
              className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
            >
              <ChartComponent.PieChartUserOverview />
            </Grid>
            <Grid
              item
              desktop={0.8}
              className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
            >
              <Calendar
                onChange={onDateChange}
                className="bg-zinc-100 rounded-[20px] border-transparent h-[100%] w-[100%]"
              />
            </Grid>
            <Grid
              item
              desktop={1}
              className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
            >
              <p className="text-[16px] text-blue-main ml-2 mt-1">
                รีวิวจากผู้ป่วย
              </p>
              <p className="text-[13px] ml-2 mt-1">คะแนนของคุณ</p>
              <Rating name="read-only" value={5.0} readOnly />
              <Stack
                direction={{ laptop: 'row' }}
                className="flex gap-[8px] text-black-main"
              >
                <Stack direction={{ laptop: 'column' }} className=" w-[100%] ">
                  <Stack
                    direction={{ laptop: 'row' }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span className="ml-1">5</span>
                    <Stack direction={{ laptop: 'row' }} className="ml-1">
                      <Rating name="read-only" value={5.0} readOnly />
                    </Stack>
                    <div className="bg-gray-main w-[40%] h-[20px]"></div>
                    <span className="ml-1">0</span>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction={{ laptop: 'row' }}
                className="flex gap-[8px] text-black-main "
              >
                <Stack direction={{ laptop: 'column' }} className=" w-[100%] ">
                  <Stack
                    direction={{ laptop: 'row' }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span className="ml-1">4</span>
                    <Stack direction={{ laptop: 'row' }} className="ml-1">
                      <Rating name="read-only" value={4.0} readOnly />
                    </Stack>
                    <div className="bg-gray-main w-[40%] h-[20px]"></div>
                    <span className="ml-1">0</span>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction={{ laptop: 'row' }}
                className="flex gap-[8px] text-black-main "
              >
                <Stack direction={{ laptop: 'column' }} className=" w-[100%] ">
                  <Stack
                    direction={{ laptop: 'row' }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span className="ml-1">3</span>
                    <Stack direction={{ laptop: 'row' }} className="ml-1">
                      <Rating name="read-only" value={3.0} readOnly />
                    </Stack>
                    <div className="bg-gray-main w-[40%] h-[20px]"></div>
                    <span className="ml-1">0</span>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction={{ laptop: 'row' }}
                className="flex gap-[8px] text-black-main"
              >
                <Stack direction={{ laptop: 'column' }} className=" w-[100%] ">
                  <Stack
                    direction={{ laptop: 'row' }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span className="ml-1">2</span>
                    <Stack direction={{ laptop: 'row' }} className="ml-1">
                      <Rating name="read-only" value={2.0} readOnly />
                    </Stack>
                    <div className="bg-gray-main w-[40%] h-[20px]"></div>
                    <span className="ml-1">0</span>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction={{ laptop: 'row' }}
                className="flex gap-[8px] text-black-main"
              >
                <Stack direction={{ laptop: 'column' }} className=" w-[100%] ">
                  <Stack
                    direction={{ laptop: 'row' }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span className="ml-1">1</span>
                    <Stack direction={{ laptop: 'row' }} className="ml-1">
                      <Rating name="read-only" value={1.0} readOnly />
                    </Stack>
                    <div className="bg-gray-main w-[40%] h-[20px]"></div>
                    <span className="ml-1">0</span>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              desktop={1}
              className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
            >
              <p className="text-[16px] text-blue-main ml-2 mt-1">
                ผู้ป่วยจองทั้งหมดของวันนี้{' '}
                {!patientConsultOnlineList.fetching &&
                  !patientConsultOnlineList.error && (
                    <span className="text-black-main text-[18px]">
                      {' '}
                      {patientConsultOnlineList.data.getConsultList.length}{' '}
                    </span>
                  )}
                คน
              </p>
              <div
                className="overflow-y h-[200px]  text-[13px]"
                style={{ overflowY: 'auto' }}
              >
                {!patientConsultOnlineList.fetching &&
                  !patientConsultOnlineList.error &&
                  patientConsultOnlineList.data.getConsultList.map(
                    (item: any) => (
                      <Stack
                        direction={{ laptop: 'row' }}
                        className="flex gap-[8px] text-white-main mt-2"
                      >
                        <Stack
                          direction={{ laptop: 'column' }}
                          className="bg-blue-main rounded-[20px] p-[8px] w-[80%] "
                        >
                          <Stack
                            direction={{ laptop: 'row' }}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <span className="ml-1">{item.time} น.</span>
                            <Stack
                              direction={{ laptop: 'row' }}
                              className="ml-1"
                            >
                              <IcoOnline className="h-[20px] w-[20px] bg-cover bg-center" />
                              <IcoPhp className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                            </Stack>
                            <span
                              className="ml-4"
                              onClick={() => {
                                setData(item)
                                setOpenModal(true)
                              }}
                            >
                              {item.user.firstname} {item.user.lastname}
                            </span>
                            <Stack
                              direction={{ laptop: 'row' }}
                              className="ml-4"
                            >
                              <Link
                                to={`${route.OVERAll_PAGE_URL}/${item.uid}/${item.cId}`}
                              >
                                <IcoEdit className="h-[20px] w-[20px] bg-cover bg-center " />
                              </Link>
                              {item.isDiagnose ? (
                                <IcoCorrect className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                              ) : (
                                <IcoWrong className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack
                          direction={{ laptop: 'column' }}
                          className="bg-blue-main rounded-[20px] p-[8px] w-[20%] "
                          justifyContent="space-evenly"
                          alignItems="center"
                        >
                          <span>{user['firstname']}</span>
                        </Stack>
                      </Stack>
                    ),
                  )}
              </div>
            </Grid>
            <Grid
              item
              desktop={1}
              className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
            >
              <p className="text-[16px] text-blue-main ml-2 mt-1">
                ผู้ป่วย Walk-in ทั้งหมดของวันนี้
                {!patientConsultWalkinList.fetching &&
                  !patientConsultWalkinList.error && (
                    <span className="text-black-main text-[18px]">
                      {' '}
                      {patientConsultWalkinList.data.getConsultList.length}{' '}
                    </span>
                  )}
                คน
              </p>
              <div
                className="overflow-y h-[200px] mt-2 text-[13px] text-white-main"
                style={{ overflowY: 'auto' }}
              >
                {!patientConsultWalkinList.fetching &&
                  !patientConsultWalkinList.error &&
                  patientConsultWalkinList.data.getConsultList.map(
                    (item: any) => (
                      <Stack
                        direction={{ laptop: 'row' }}
                        className="bg-blue-main rounded-[20px] w-[100%] mt-2"
                      >
                        <Stack
                          direction={{ laptop: 'column' }}
                          className="bg-blue-main rounded-[20px] p-[8px] w-[100%] "
                        >
                          <Stack
                            direction={{ laptop: 'row' }}
                            justifyContent="space-between"
                          >
                            <Stack
                              className="ml-4"
                              direction={{ laptop: 'row' }}
                            >
                              <IcoPhp className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                              <span
                                className="ml-4"
                                onClick={() => {
                                  setData(item)
                                  setOpenModal(true)
                                }}
                              >
                                {item.user.firstname} {item.user.lastname}
                              </span>
                            </Stack>
                            <Stack
                              className="ml-4"
                              direction={{ laptop: 'row' }}
                            >
                              <Link
                                to={`${route.OVERAll_PAGE_URL}/${item.uid}/${item.cId}`}
                              >
                                <IcoEdit className="h-[20px] w-[20px] bg-cover bg-center " />
                              </Link>
                              {item.isDiagnose ? (
                                <IcoCorrect className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                              ) : (
                                <IcoWrong className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    ),
                  )}
              </div>
            </Grid>
          </Grid>
        </Stack>
      </div>
      <ModalComponent.ConsultModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={data}
      />
    </div>
  )
}
export default DaskboardPage
