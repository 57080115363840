import React, { useState } from 'react'
import { Divider, Pagination } from '@mui/material'
import { Icon } from '@iconify/react'
import { useQuery } from 'urql'

import { ElementComponent, ModalComponent, SkeletonComponent } from 'components'
import { date } from 'utils'
import { MedicalQuery } from 'services/graphql/medicalService'

interface HistoryDiagnoseCardProps {
  puId: string
  ddId?: string
}

interface HitoryDiagnoseTableProps {
  data: {
    ddId: string
    diagnosisResult: string
    description: string
    doctorUser: {
      prefix: string
      firstname: string
      lastname: string
    }
    updatedAt: string
  }[]
  totalData: number
  pageSize: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

function HitoryDiagnoseTable({
  data,
  totalData,
  page,
  pageSize,
  setPage,
}: HitoryDiagnoseTableProps) {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [resultData, setResultData] = useState<any>({})

  const columns: string[] = [
    'ลำดับ',
    'ผลลัพธ์การวินิจฉัย',
    'ผู้ตรวจ',
    'วันที่วินิจฉัย',
    'ตัวเลือก',
  ]

  const OptionData = (data: any) => {
    const handleOpenModal = (data: any) => {
      setOpenModal(true)
      setResultData(data)
    }

    return (
      <>
        <div className="flex justify-center">
          <Icon
            icon="solar:document-bold"
            className="w-[28px] h-[28px] cursor-pointer hover:text-blue-main"
            onClick={() => handleOpenModal(data)}
          />
        </div>
      </>
    )
  }

  const handleFormatRowData = (rowData: any[]): any[] => {
    const result = rowData?.map((item: any, index: number) => ({
      [columns[0]]: index + 1 + (page - 1) * pageSize,
      [columns[1]]: item.diagnosisResult,
      [columns[2]]: `${item.doctorUser.prefix} ${item.doctorUser.firstname} ${item.doctorUser.lastname}`,
      [columns[3]]: date.convertToShowDateTime(item.updatedAt),
      [columns[4]]: OptionData(item),
    }))

    return result
  }

  return (
    <div className="w-full">
      <ElementComponent.Table col={columns} row={handleFormatRowData(data)} />
      <div className="flex justify-center mt-[30px]">
        <Pagination
          shape="rounded"
          variant="outlined"
          count={Math.ceil(totalData / pageSize)}
          page={page}
          onChange={(_, newPage: number) => setPage(newPage)}
        />
      </div>

      <ModalComponent.ResultDiagnoseModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={resultData}
      />
    </div>
  )
}

export default function HistoryDiagnoseCard({
  puId,
  ddId,
}: HistoryDiagnoseCardProps) {
  const pageSize = 5
  const [page, setPage] = useState<number>(1)

  const [diagosisData] = useQuery(
    MedicalQuery.getDiagosisData(puId, pageSize, (page - 1) * pageSize),
  )
  const [countDiagnosisDatas] = useQuery(MedicalQuery.countDiagnosisDatas(puId))

  return (
    <div
      className="px-[20px] py-[35px] rounded-[10px] bg-white-main"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <p className="text-[20px] font-bold">ประวัติการวินิจฉัย</p>
      <Divider sx={{ marginTop: '18px', marginBottom: '25px' }} />
      {!diagosisData.fetching &&
      !diagosisData.error &&
      !countDiagnosisDatas.fetching &&
      !countDiagnosisDatas.error ? (
        <HitoryDiagnoseTable
          data={diagosisData.data.getDiagnosisDatas.filter(
            (item: any) => item.ddId !== ddId,
          )}
          totalData={
            countDiagnosisDatas.data.aggregateDiagnosisDatas._count.ddId
          }
          page={page}
          pageSize={pageSize}
          setPage={setPage}
        />
      ) : (
        SkeletonComponent.TableSkeleton({ columnsAmount: 5 })
      )}
    </div>
  )
}
