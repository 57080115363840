import React, { useState, useEffect } from 'react'
import { Grid, Divider, Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'urql'
import { Icon } from '@iconify/react'

import {
  CardComponent,
  MenuComponent,
  ElementComponent,
  SkeletonComponent,
} from 'components'
import { PatientQuery } from 'services/graphql/patientService'
import { MedicalMutate } from 'services/graphql/medicalService'
import { route } from 'settings'
import { general } from 'utils'

const menuList = [
  { title: 'ข้อมูลคนไข้', link: route.PATIENT_PAGE_URL },
  { title: 'ดูรายละเอียดข้อมูล', link: '' },
]

const userDiseaseInitial = {
  underlying: [],
  specialCase: '',
}

export default function PatientDataPage() {
  const { patientId } = useParams()
  const [patientData, setPatientData] = useState<any>({})
  const [diseaseData, setDiseaseData] = useState<any[]>([])
  const [userDisease, setUserDisease] = useState<any>(userDiseaseInitial)

  // -> Execute function for update user diagenosis.
  const [updateUserInfo, executeUserDisease] = useMutation(
    MedicalMutate.updateUserInfo,
  )
  const [patientUserData] = useQuery(PatientQuery.getUserByUserId(patientId))
  const [patientDisease] = useQuery(PatientQuery.getUserDiseaseData())
  const [patientMedical] = useQuery(
    PatientQuery.getPatientMedicalData(patientId),
  )

  const handleDiseaseData = (diseaseData: any[], data: any[]): any[] => {
    const result = diseaseData.reduce((result: any[], item: any) => {
      const medicaltransection: any = data.find(
        (medicalData: any) => medicalData.dId === item.dId,
      )

      return [
        ...result,
        ...medicaltransection.diseaseAttibute.flatMap((disease: any) => {
          if (disease.userMedTrasaction.length > 0) {
            return {
              diseaseAttibute: {
                daId: disease.daId,
                showFlag: item.showFlag,
                medName: disease.medName,
                type: disease.type,
                unitEnglish: disease.unitEnglish,
                disease: {
                  dId: item.dId,
                  medName: item.medName,
                  type: item.type,
                },
              },
              umtId: disease.userMedTrasaction[0]?.umtId ?? '',
              value: disease.userMedTrasaction[0]?.value ?? null,
              desciption: disease.userMedTrasaction[0]?.desciption ?? null,
              imagePath: disease.userMedTrasaction[0]?.imagePath ?? '',
            }
          } else return []
        }),
      ]
    }, [])
    return result
  }

  // -> Function handle data for check create new or delete that.
  const formatUserDiseaseSubmit = (
    originData: any,
    newData: any,
  ): { created: string[]; deleted: string[] } => {
    const createList: string[] = []
    const deleteList: string[] = []

    if (newData.length) {
      for (const dataItem of newData) {
        if (
          !originData.some(
            (originItem: any) =>
              originItem.underlyingDisease.udId === dataItem.key,
          )
        )
          createList.push(dataItem.key)
      }

      for (const dataItem of originData) {
        if (
          !newData.some(
            (newItem: any) => newItem.key === dataItem.underlyingDisease.udId,
          )
        )
          deleteList.push(dataItem.uudtId)
      }
    }

    return {
      created: createList,
      deleted: deleteList,
    }
  }

  const onClickSave = async () => {
    // -> Execute part for update user Diagnosis.
    const { created, deleted } = formatUserDiseaseSubmit(
      patientData.UserMedicalDemograhicData.userUnderlyingDiseaseTrasaction,
      userDisease.underlying,
    )

    if (
      created.length > 0 ||
      deleted.length > 0 ||
      userDisease.specialCase !== ''
    )
      await executeUserDisease({
        data: {
          UserMedicalDemograhicData: {
            update: {
              data: {
                allergic: {
                  set: userDisease.specialCase,
                },
                ...(created.length > 0 || deleted.length > 0
                  ? {
                      userUnderlyingDiseaseTrasaction: {
                        ...(created.length
                          ? {
                              create: created.map((item: any) => ({
                                underlyingDisease: {
                                  connect: {
                                    udId: item,
                                  },
                                },
                              })),
                            }
                          : {}),
                        ...(deleted.length
                          ? {
                              deleteMany: deleted.map((item: any) => ({
                                uudtId: {
                                  equals: item,
                                },
                              })),
                            }
                          : {}),
                      },
                    }
                  : {}),
              },
            },
          },
        },
        where: {
          uId: patientData.uId,
        },
      })
  }

  useEffect(() => {
    if (
      patientUserData.fetching ||
      !patientUserData.data ||
      patientDisease.fetching ||
      !patientDisease.data ||
      patientMedical.fetching ||
      !patientMedical.data
    )
      return

    const { getUser } = patientUserData.data
    const { getDiseases } = patientDisease.data
    const { getMedicalDataByUId } = patientMedical.data

    setPatientData(getUser)
    setDiseaseData(handleDiseaseData(getDiseases, getMedicalDataByUId))
  }, [patientUserData.data, patientDisease.data, patientMedical.data])

  return (
    <div className="p-[40px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      {!patientUserData.fetching && !patientUserData.error ? (
        <div className="mt-[25px] flex items-center justify-between">
          <div className="flex items-center gap-[10px]">
            <Icon
              icon="solar:document-medicine-bold"
              className="w-[35px] h-[35px]"
            />
            <p className="text-[24px]">
              {`ดูรายละเอียดข้อมูล ${patientData?.prefix} ${patientData?.firstname} ${patientData?.lastname}`}
            </p>
          </div>
          <ElementComponent.Button
            id="back-to-patient-data"
            text="กลับสู่หน้าข้อมูลคนไข้"
            buttonTextSize="18px"
            width="200px"
            height="45px"
            shape="outlined"
            onAction={() => general.http.goto(route.PATIENT_PAGE_URL)}
          />
        </div>
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{ marginTop: '25px' }}
          height="40px"
          width="50%"
        />
      )}

      <Divider sx={{ marginY: '30px' }} />

      <Grid container columnSpacing="20px">
        {!patientUserData.fetching &&
        !patientUserData.error &&
        !patientDisease.fetching &&
        !patientDisease.error ? (
          <Grid item mobile>
            <CardComponent.MedicalPatientCard
              isLoading={updateUserInfo.fetching}
              onChangeValue={setUserDisease}
              hnId={patientData?.hnId ?? ''}
              gender={patientData?.UserMedicalDemograhicData?.gender}
              age={patientData?.UserMedicalDemograhicData?.birthdate}
              allergic={patientData?.UserMedicalDemograhicData?.allergic}
              underlying={
                patientData?.UserMedicalDemograhicData
                  ?.userUnderlyingDiseaseTrasaction
              }
            />

            {diseaseData?.length > 0 && (
              <CardComponent.MedicalTransection
                transaction={diseaseData}
                puId={patientId}
              />
            )}
          </Grid>
        ) : (
          <Grid item mobile>
            <SkeletonComponent.HealthDataCradSkeleton />
            <SkeletonComponent.MedicalPatientCardSkeleton />
          </Grid>
        )}

        {!patientUserData.fetching && !patientUserData.error ? (
          <Grid item mobile>
            <div className="flex flex-col gap-5 items-end">
              <CardComponent.HistoryDiagnoseCard puId={patientId} />

              <ElementComponent.Button
                disable={patientUserData.fetching}
                id="submit-diagnose-button"
                width="w-main"
                height="52px"
                text="ยืนยันการเปลี่ยนเเปลงข้อมูล"
                onAction={onClickSave}
              />
            </div>
          </Grid>
        ) : (
          <Grid item mobile>
            <SkeletonComponent.HistoryDiagnoseCardSkeleton />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
