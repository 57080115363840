export const getPatientList = (
  condition: object,
  take: number,
  skip: number,
) => {
  const patientListSchema = `
    query getUsers($where: UserWhereInput, $take: Int, $skip: Int, $orderBy: [UserOrderByWithRelationInput!]) {
      getUsers(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
        uId
        hnId
        prefix
        firstname
        lastname
        UserMedicalDemograhicData {
          birthdate
          gender
          district
          province
        }
      }
    }
  `

  return {
    query: patientListSchema,
    variables: {
      where: { ...condition },
      take: take,
      skip: skip,
      orderBy: {
        hnId: { sort: 'asc' },
      },
    },
  }
}

export const getPatientCount = () => {
  const patientCountSchema = `
    query aggregateUser($_count: UserCountAggregateInput)  {
        aggregateUser(_count: $_count)  {
            _count {
                uId
            }
        }
    }
  `

  return {
    query: patientCountSchema,
    _count: {
      uId: true,
    },
  }
}

export const getUserByUserId = (uId: string) => {
  const userDoctorSchema = `
    query getUser ($where: UserWhereUniqueInput!) {
      getUser (where: $where) {
          uId
          hnId
          prefix
          firstname
          lastname
          imagePath
          UserAuth {
              identifyId
              phoneNumber
          }
          UserMedicalDemograhicData {
              allergic
              gender
              birthdate
              userUnderlyingDiseaseTrasaction {
                  uudtId
                  underlyingDisease {
                      udId
                      medName
                      thaiName
                  }
              }
          }
          EnterpriseTreatmentPlace {
            thaiName
          }
          _count {
              patientMedicalData
          }
      }
    }
  `

  return {
    query: userDoctorSchema,
    variables: {
      where: {
        uId: uId,
      },
    },
  }
}

export const getUserDiseaseData = () => {
  const userDiseaseData = `
    query getDiseases($where: DiseaseWhereInput){
      getDiseases(where: $where) {
        dId
        medName
        engName
        thaiName
        showFlag
        diseaseAttibute {
            daId
            type
            engName
            thaiName
            medName
            unitEnglish
            userMedTrasaction {
                umtId
                value
                desciption
                imagePath
                createdAt
                updatedAt
            }
        }
        type
      }
    }
  `

  return {
    query: userDiseaseData,
  }
}

export const getPatientMedicalData = (puId: string) => {
  const patientMedicalDataSchema = `
    query getMedicalDataByUId($where: UserMedicalDataWherePuIdInput!, $take: Int) {
      getMedicalDataByUId(where: $where, take: $take) {
            dId
            medName
            engName
            thaiName
            showFlag
            diseaseAttibute {
                daId
                type
                engName
                thaiName
                medName
                unitEnglish
                userMedTrasaction {
                    umtId
                    value
                    desciption
                    imagePath
                    createdAt
                    updatedAt
                }
            }
            type
      }
    }
  `

  return {
    query: patientMedicalDataSchema,
    variables: {
      where: {
        puId: puId,
      },
    },
  }
}

export const getPatientConsultList = (
  dateConsult: string,
  typeConsult: string,
  doctorUid: string,
) => {
  const getConsultList = `
    query getConsultList ($where: ConsultWhereInput) {
      getConsultList (where: $where) {
          cId
          placeName
          time
          ctId
          textConsult
          uid
          createdAt
          dateConsult
          isDiagnose
          user {
              firstname
              lastname
          }
          consultType {
            typeName
          }
      }
  }
  `

  return {
    query: getConsultList,
    variables: {
      where: {
        dateConsult: {
          equals: dateConsult,
        },
        typeConsult: {
          equals: typeConsult,
        },
        doctorUid: {
          equals: doctorUid,
        },
      },
    },
  }
}

export const getDiagnoseLastOne = (uId: string) => {
  const getDiagnoseLastOne = `
    query getDiagnoseLastOne ($where: DiagnoseWhereInput, $orderBy: [DiagnoseOrderByWithRelationInput!]) {
      getDiagnoseLastOne (where: $where, orderBy: $orderBy) {
          icdData
          allergy
          detail
          recommend
          drugData
          costTotal
          incomeTotal
          uId
          duId
          createdAt
      }
  }
  `

  return {
    query: getDiagnoseLastOne,
    variables: {
      where: {
        uId: {
          equals: uId,
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}

export const getConsultOne = (uId: string) => {
  const getConsultOne = `
  query getConsultOne ($where: ConsultWhereInput, $orderBy: [ConsultOrderByWithRelationInput!]) {
    getConsultOne (where: $where, orderBy: $orderBy) {
        cId
        placeName
        time
        ctId
        textConsult
        uid
        createdAt
        dateConsult
        finishedFlag
        temp
        bp_sys
        bp_dia
        hr
        rr
        user {
            firstname
            lastname
        }
        consultType {
          typeName
        }
    }
}
  `

  return {
    query: getConsultOne,
    variables: {
      where: {
        uid: {
          equals: uId,
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}

export const getDiagnoseList = (uId: string) => {
  const getDiagnoseList = `
    query getDiagnoseList ($where: DiagnoseWhereInput, $orderBy: [DiagnoseOrderByWithRelationInput!]) {
      getDiagnoseList (where: $where, orderBy: $orderBy) {
          icdData
          allergy
          detail
          recommend
          drugData
          costTotal
          incomeTotal
          uId
          duId
          createdAt
          imageFile
      }
  }
  `

  return {
    query: getDiagnoseList,
    variables: {
      where: {
        uId: {
          equals: uId,
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}
