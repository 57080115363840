import React from 'react'
import { Divider } from '@mui/material'

import { FormComponent } from 'components'

interface MedicalPatientCardProps {
  onChangeValue?: Function
  isLoading?: boolean
  disable?: boolean
  hnId: number
  gender: string
  age: string
  allergic: string
  underlying: any[]
}

export default function MedicalPatientCard({
  onChangeValue,
  isLoading = false,
  disable = false,
  hnId,
  gender,
  age,
  allergic,
  underlying,
}: MedicalPatientCardProps) {
  return (
    <div
      className="px-[20px] py-[35px] rounded-[10px] bg-white-main"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <p className="text-[20px] font-bold">{`HN : ${Array(
        6 - hnId?.toString()?.length,
      )
        .fill(0)
        .join('')}${hnId}`}</p>

      <Divider sx={{ marginTop: '18px', marginBottom: '25px' }} />
      <FormComponent.PatientDiagnoseForm
        gender={gender}
        age={age}
        allergic={allergic}
        patientUnderlying={underlying}
        onChangeValue={onChangeValue}
        disable={disable}
        loading={isLoading}
      />
    </div>
  )
}
