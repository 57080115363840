import { Grid, Stack } from '@mui/material'
import { Navbar } from 'components/navbar'
import { route } from 'settings'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'
import { useParams } from 'react-router-dom'
import { PatientQuery } from 'services/graphql/patientService'
import { useQuery } from 'urql'
import { useEffect, useState } from 'react'
function MedicalPage() {
  const { patientId } = useParams()
  const { cId } = useParams()
  const [data, setData] = useState([])
  const [patientDiagnoseList] = useQuery(
    PatientQuery.getDiagnoseList(patientId),
  )

  useEffect(() => {
    if (patientDiagnoseList.fetching || !patientDiagnoseList.data) return
    let dataMainItem = []
    patientDiagnoseList.data.getDiagnoseList.map((item: any) => {
      dataMainItem.push({
        date: item.createdAt.split('T')[0],
        drugData: item.drugData,
        drugShowData: [],
      })
    })
    dataMainItem.map((dataItem: any, index: number) => {
      let drugTypeOneItem = []
      const drugArray = JSON.parse(dataItem.drugData)
      //find typeOne
      drugArray.map((drugItem: any) => {
        if (drugTypeOneItem.length === 0) {
          drugTypeOneItem.push({ typeOne: drugItem.typeOne })
        } else {
          let isExist = false
          drugTypeOneItem.map((itemTypeOne: any) => {
            if (itemTypeOne.typeOne === drugItem.typeOne) {
              isExist = true
            }
          })
          if (!isExist) {
            drugTypeOneItem.push({ typeOne: drugItem.typeOne })
          }
        }
      })
      let finalDataItem = []
      //find drugByTypeOne
      drugTypeOneItem.map((itemTypeOne: any) => {
        let drugDataItem = []
        drugArray.map((drugItem: any) => {
          if (itemTypeOne.typeOne === drugItem.typeOne) {
            drugDataItem.push(drugItem)
          }
        })
        finalDataItem.push({ typeOne: itemTypeOne.typeOne, data: drugDataItem })
      })
      dataMainItem[index].drugShowData = finalDataItem
    })
    setData(dataMainItem)
    //alert(JSON.stringify(dataMainItem))
    //JSON.parse(patientDiagnoseLastOne.data.getDiagnoseLastOne.icdData)
  }, [patientDiagnoseList.data])

  return (
    <div className="p-[40px]">
      <Grid
        container
        justifyContent="center"
        columns={{ desktop: 2.2 }}
        gap="8px"
      >
        <Grid item desktop={0.1} className=" p-[8px]" justifyContent="center">
          <Navbar
            activeMenu={route.MEDICAL_PAGE_URL}
            patientId={patientId}
            cId={cId}
          />
        </Grid>
        <Grid item desktop={1.9} className="bg-zinc-100 rounded-[20px]">
          {data.length > 0 &&
            data.map((item: any) => (
              <div
                className="w-[100%] bg-zinc-100 rounded-[20px] p-[36px]"
                style={{ overflowY: 'auto' }}
              >
                <span className="text-[16px] text-white-main w-[100px] p-[10px]  bg-blue-main rounded-[20px] text-center">
                  ยาวันที่
                </span>
                <span className="text-[16px] ml-2">{item.date}</span>
                <Grid
                  container
                  columns={{ desktop: 3.3 }}
                  gap="20px"
                  className="mt-10"
                >
                  {item.drugShowData.length > 0 &&
                    item.drugShowData.map((drugShowData: any) => (
                      <Grid item desktop={1}>
                        <div className="w-[100%] bg-zinc-200 rounded-[20px] p-[16px]">
                          <Stack
                            direction={{ laptop: 'row' }}
                            justifyContent="center"
                          >
                            <span className="text-[14px] text-white-main  p-[10px]  bg-blue-main rounded-[20px] text-center mt-[-35px]">
                              กลุ่มยา{drugShowData.typeOne}
                            </span>
                          </Stack>
                          <Grid
                            container
                            columns={{ desktop: 2.2 }}
                            gap="10px"
                            className="mt-4"
                          >
                            <Grid item desktop={0.4}>
                              <ElementComponent.Image
                                fullImage={true}
                                src={Pics.IcoBloodPressure}
                              />
                            </Grid>
                            <Grid item desktop={1.6}>
                              <div>
                                {drugShowData.data.length > 0 &&
                                  drugShowData.data.map(
                                    (drugData: any, index: number) => (
                                      <>
                                        <p className="text-[16px]">
                                          {index + 1}.{drugData.dName}{' '}
                                          {drugData.quantity} {drugData.unit}
                                        </p>
                                        <div className="ml-4">
                                          <span className="text-[14px] text-blue-main">
                                            {drugData.dutName}
                                          </span>
                                        </div>
                                      </>
                                    ),
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </div>
            ))}
        </Grid>
      </Grid>
    </div>
  )
}
export default MedicalPage
