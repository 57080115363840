import React from 'react'

import { MedicalQuery } from 'services/graphql/medicalService'
import { date } from 'utils'

import { useQuery } from 'urql'

interface CardLatestDataProps {
  dieaseData: {
    adaId: string
    thaiName: string
    unitThai: string
  }
  puId: string
}

function CardLatestData({ dieaseData, puId }: CardLatestDataProps) {
  const [medicalTrasactionData] = useQuery(
    MedicalQuery.getLatestMedDataTransactionByadaID(dieaseData.adaId, puId),
  )

  const RenderData = () => {
    if (medicalTrasactionData.fetching) return <h1>Loading...</h1>

    if (medicalTrasactionData.error) return <h1>Error</h1>

    const data = medicalTrasactionData.data.getMedDataTransactions

    return (
      <>
        <div className="flex justify-center">
          <h1 className="font-bold">
            {data.length !== 0
              ? date.convertToShowDateThai(data[0].createdAt)
              : 'ไม่มีข้อมูล'}
          </h1>
        </div>
        <div className="flex flex-row justify-center gap-7">
          <div className="border border-blue-main rounded-xl">
            <h1 className="px-3">{dieaseData.thaiName}</h1>
          </div>
          <h1>{data.length !== 0 ? data[0].value : '-'}</h1>
          <h1>{dieaseData.unitThai}</h1>
        </div>
      </>
    )
  }

  return (
    <div className="h-full flex flex-col items-center gap-2">
      <RenderData />
    </div>
  )
}

export default CardLatestData
