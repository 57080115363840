import React from 'react'

import { ElementComponent } from 'components'

interface DiagnoseFormProps {
  loading: boolean
  form: any
}

export default function DiagnoseForm({ loading, form }: DiagnoseFormProps) {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-[20px]">
        <ElementComponent.InputBar
          id="result-diagnose"
          name="result"
          label="ผลลัพธ์การวินิจฉัยโรค"
          placeholder="ผลลัพธ์การวินิจฉัยโรค"
          isLoading={loading}
          height="50px"
          value={form.values.result}
          onChange={(value: string) => form.setFieldValue('result', value)}
          errorText={form.errors.result}
          error={form.touched.result && Boolean(form.errors.result)}
        />

        <ElementComponent.TextArea
          id="dianose-detail"
          isLoading={loading}
          name="diagnose"
          minlines={8}
          label="รายละเอียดการวินิจฉัยเพิ่มเติม"
          placeholder="รายละเอียดการวินิจฉัยเพิ่มเติม"
          value={form.values.diagnose}
          onChange={(value: string) => form.setFieldValue('diagnose', value)}
          errorText={form.errors.diagnose}
          error={form.touched.diagnose && Boolean(form.errors.diagnose)}
        />

        <ElementComponent.TextArea
          id="suggestion-detail"
          isLoading={loading}
          name="suggestion"
          minlines={8}
          label="คำแนะนำสำหรับคนไข้"
          placeholder="คำแนะนำสำหรับคนไข้"
          value={form.values.suggestion}
          onChange={(value: string) => form.setFieldValue('suggestion', value)}
          errorText={form.errors.suggestion}
          error={form.touched.suggestion && Boolean(form.errors.suggestion)}
        />
      </div>
    </div>
  )
}
