import React, { useState } from 'react'
import { useMutation } from 'urql'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
//import { ConnectedFocusError } from 'focus-formik-error'

import { ElementComponent, AlertComponent } from 'components'
import { UserMutate } from 'services/graphql/userService'
import { useResponsive } from 'hooks'
import { ProfileType } from 'types'

const initialValues: ProfileType.ResetPasswordDataType = {
  password: '',
  newPassword: '',
  confirmPassword: '',
}

const validationSchema = yup.object({
  password: yup.string().required('กรุณากรอกรหัสผ่านเดิม'),
  newPassword: yup
    .string()
    .min(
      8,
      'กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษ  และ ตัวเลขอย่างละ 1 ตัว กับ มีความยาวตั้งแต่ 8 ถึง 32 ตัว',
    )
    .max(
      32,
      'กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษ  และ ตัวเลขอย่างละ 1 ตัว กับ มีความยาวตั้งแต่ 8 ถึง 32 ตัว',
    )
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])/,
      'กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษ  และ ตัวเลขอย่างละ 1 ตัว กับ มีความยาวตั้งแต่ 8 ถึง 32 ตัว',
    )
    .required('กรุณากรอกรหัสผ่านใหม่'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      'กรุณากรอกยืนยันรหัสผ่านให้ตรงกับรหัสผ่านใหม่',
    )
    .required('กรุณากรอกยืนยันรหัสผ่านใหม่'),
})

export default function ChangePassword() {
  const { isMobile } = useResponsive()
  const [errorModalData, setErrorModalData] = useState({
    title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })
  const [openResetModal, setOpenResetModal] = useState<boolean>(false)
  const [resetData, setResetData] = useState({ phone: '', password: '' })

  const [changePasswordMutate, executeMutation] = useMutation(
    UserMutate.changePassword,
  )

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  const changePassword = async (
    values: ProfileType.ResetPasswordDataType,
    actions,
  ) => {
    await executeMutation({
      data: {
        uId: '',
        oldPassword: values.password,
        password: values.newPassword,
      },
    }).then((res) => {
      if (res.error) {
        const error: any = res.error
        const code: number = error.graphQLErrors[0].originalError.code

        if (code == 400) {
          setErrorModalData({
            title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
            description: 'รหัสผ่านเดิมไม่ถูกต้อง',
            open: true,
          })
          return
        }

        setErrorModalData({
          title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
          description: 'กรุณาทดลองใหม่อีกครั้ง',
          open: true,
        })
        return
      }

      setResetData({
        phone: res.data.changePassword.phoneNumber,
        password: res.data.changePassword.password,
      })
      setOpenResetModal(true)
      actions.resetForm()
    })
  }

  return (
    <div className="w-full max-w-[860px]">
      <div className="flex items-center gap-[20px] mb-[18px]">
        <Icon icon="material-symbols:lock" className="text-[24px]" />
        <p className="text-[20px] font-bold">เปลี่ยนรหัสผ่าน</p>
      </div>

      <p className="text-[16px] text-black-main opacity-50 mb-[30px]">
        เพื่อความปลอดภัยในบัญชีของคุณ อย่าเปิดเผยรหัสผ่านของคุณกับผู้อื่น
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={changePassword}
      >
        {(formik) => (
          <Form className="w-full">
            {/*<ConnectedFocusError />*/}
            <div
              className="rounded-[10px] bg-white-main py-[60px] px-[18px] laptop:p-[60px] text-[16px] flex flex-col gap-[10px] mb-[33px]"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <ElementComponent.PasswordBar
                id="old-password-input"
                name="password"
                label="รหัสผ่านเดิม"
                isLoading={changePasswordMutate.fetching}
                placeholder="รหัสผ่านเดิม"
                height="50px"
                value={formik.values.password}
                errorText={formik.errors.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                onChange={(text: string) =>
                  formik.setFieldValue('password', text)
                }
              />

              <ElementComponent.PasswordBar
                id="new-password-input"
                name="newPassword"
                label="รหัสผ่านใหม่"
                isLoading={changePasswordMutate.fetching}
                placeholder="รหัสผ่านใหม่"
                height="50px"
                value={formik.values.newPassword}
                errorText={formik.errors.newPassword}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                onChange={(text: string) =>
                  formik.setFieldValue('newPassword', text)
                }
              />

              <div className="my-[30px] ">
                <p>รหัสผ่านต้องมีความยาวตั้งแต่ 8 ถึง 32 ตัว</p>
                <p>ประกอบด้วย</p>

                <ul className="list-disc ml-[20px]">
                  <li>ตัวอักษรอังกฤษ (a-z, A-Z) อย่างน้อย 1 ตัว</li>
                  <li>ตัวเลข อย่างน้อย 1 ตัว</li>
                </ul>
              </div>

              <ElementComponent.PasswordBar
                id="confirm-password-input"
                name="confirmPassword"
                label="ยืนยันรหัสผ่านใหม่"
                isLoading={changePasswordMutate.fetching}
                placeholder="ยืนยันรหัสผ่านใหม่"
                height="50px"
                value={formik.values.confirmPassword}
                errorText={formik.errors.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                onChange={(text: string) =>
                  formik.setFieldValue('confirmPassword', text)
                }
              />
            </div>

            <div className="flex flex-col laptop:flex-row laptop:justify-end w-full mt-14">
              <ElementComponent.Button
                id="submit-button"
                submit
                loading={changePasswordMutate.fetching}
                text="ยืนยันการส่งข้อมูล"
                width={isMobile ? '100%' : '250px'}
                height="52px"
              />
            </div>
          </Form>
        )}
      </Formik>

      <AlertComponent.ResetPassModal
        phone={resetData.phone}
        password={resetData.password}
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
      />

      <AlertComponent.ErrorModal
        title={errorModalData.title}
        description={errorModalData.description}
        open={errorModalData.open}
        onClose={onCloseErrorModal}
      />
    </div>
  )
}
