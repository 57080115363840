import React from 'react'
import { Skeleton, Grid, Divider } from '@mui/material'

export default function MedicalPatientCardSkeleton() {
  return (
    <div
      className="px-[20px] py-[35px] rounded-[10px] bg-white-main"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <Skeleton variant="rectangular" height="45px" width="50%" />
      <Divider sx={{ marginY: '20px' }} />

      <div className="flex flex-col gap-[10px]">
        <Grid container sx={{ gap: '10px' }}>
          <Grid item mobile>
            <Skeleton variant="rectangular" height="40px" />
          </Grid>
          <Grid item mobile>
            <Skeleton variant="rectangular" height="40px" />
          </Grid>
        </Grid>

        <Skeleton variant="rectangular" height="40px" />
        <Skeleton variant="rectangular" height="40px" />
      </div>
    </div>
  )
}
