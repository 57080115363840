import React from 'react'
import { Skeleton, Divider } from '@mui/material'

import { SkeletonComponent } from 'components'

export default function HistoryDiagnoseCardSkeleton() {
  return (
    <div
      className="px-[20px] py-[35px] rounded-[10px] bg-white-main"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <Skeleton variant="rectangular" height="45px" width="50%" />
      <Divider sx={{ marginY: '20px' }} />

      <SkeletonComponent.TableSkeleton
        columnsAmount={5}
        rowsAmount={5}
        heigthSkeleton={40}
      />
    </div>
  )
}
