import React from 'react'
import { NavLink } from 'react-router-dom'

import * as SVG from 'assets/svg'
import { route } from 'settings'

interface MenuItemProps {
  icon: string
  iconActive: string
  urlPath: string
  activeMenu: string
  patientId: string
  cId: string
}

const menuList = [
  {
    icon: SVG.MenuDashboard,
    iconActive: SVG.MenuDashboardActive,
    urlPath: route.OVERVIEW_PAGE_URL,
  },
  {
    icon: SVG.MenuOverAll,
    iconActive: SVG.MenuOverAllActive,
    urlPath: route.OVERAll_PAGE_URL,
  },
  {
    icon: SVG.MenuDiagnose,
    iconActive: SVG.MenuDiagnoseActive,
    urlPath: route.DIAGNOSES_PAGE_URL,
  },
  {
    icon: SVG.MenuMedical,
    iconActive: SVG.MenuMedicalActive,
    urlPath: route.MEDICAL_PAGE_URL,
  },
  {
    icon: SVG.MenuInput,
    iconActive: SVG.MenuInputActive,
    urlPath: route.INPUT_PAGE_URL,
  },
]

function MenuItem({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  icon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  iconActive,
  urlPath,
  activeMenu,
  patientId,
  cId,
}: MenuItemProps) {
  return (
    <NavLink
      to={urlPath + '/' + patientId + '/' + cId}
      /*className={({ isActive }) =>
        `flex items-center px-[20px] p-[10px] gap-[10px] ${
          isActive
            ? 'bg-blue-light text-blue-main'
            : 'text-white-main hover:bg-sky-700 hover:text-blue-light'
        }`
      }*/
    >
      {urlPath == route.OVERVIEW_PAGE_URL &&
        (urlPath + '/' + patientId == activeMenu ? (
          <SVG.MenuDashboardActive className="h-[auto] w-[100%] bg-cover bg-center item-center" />
        ) : (
          <SVG.MenuDashboard className="h-[auto] w-[100%] bg-cover bg-center " />
        ))}
      {urlPath == route.OVERAll_PAGE_URL &&
        (urlPath + '/' + patientId == activeMenu ? (
          <SVG.MenuOverAllActive className="h-[auto] w-[100%] bg-cover bg-center " />
        ) : (
          <SVG.MenuOverAll className="h-[auto] w-[100%] bg-cover bg-center " />
        ))}
      {urlPath == route.DIAGNOSES_PAGE_URL &&
        (urlPath + '/' + patientId == activeMenu ? (
          <SVG.MenuDiagnoseActive className="h-[auto] w-[100%] bg-cover bg-center " />
        ) : (
          <SVG.MenuDiagnose className="h-[auto] w-[100%] bg-cover bg-center " />
        ))}
      {urlPath == route.MEDICAL_PAGE_URL &&
        (urlPath + '/' + patientId == activeMenu ? (
          <SVG.MenuMedicalActive className="h-[auto] w-[100%] bg-cover bg-center " />
        ) : (
          <SVG.MenuMedical className="h-[auto] w-[100%] bg-cover bg-center " />
        ))}
      {urlPath == route.INPUT_PAGE_URL &&
        (urlPath + '/' + patientId == activeMenu ? (
          <SVG.MenuInputActive className="h-[auto] w-[100%] bg-cover bg-center " />
        ) : (
          <SVG.MenuInput className="h-[auto] w-[100%] bg-cover bg-center " />
        ))}
    </NavLink>
  )
}

export default function Navbar({ activeMenu, patientId, cId }) {
  return (
    <div className="h-full">
      <div className=" flex flex-col gap-[10px] ">
        {menuList.map((item: any, index: number) => (
          <MenuItem
            key={`doctor-menu-${index}`}
            icon={item.icon}
            iconActive={item.iconActive}
            urlPath={item.urlPath}
            activeMenu={activeMenu + '/' + patientId}
            patientId={patientId}
            cId={cId}
          />
        ))}
      </div>
    </div>
  )
}
