import React, { useState, useEffect } from 'react'
import { Grid, Divider } from '@mui/material'
import { useQuery } from 'urql'
import { Formik } from 'formik'
import { Icon } from '@iconify/react'

import { ElementComponent, ModalComponent } from 'components'
import { MedicalQuery } from 'services/graphql/medicalService'
import { date } from 'utils'
import _ from 'lodash'

interface HealthDataProps {
  transaction: any
  puId: string
  type?: string
}

interface TransectionProps {
  title: string
  unit: string
  value: string
  showFlag: boolean
  daId: string
  puId: string
}

interface PreviousDataProps {
  umtId: string
  value: number
}

enum CardFormatType {
  IMAGE,
  TEXT,
  IMAGE_TEXT,
}

function Transaction({
  title,
  unit,
  value,
  showFlag,
  daId,
  puId,
}: TransectionProps) {
  const [openGraphModal, setOpenGraphModal] = useState<boolean>(false)
  const [prevData, setPrevData] = useState<number>(null)
  const [transactionData] = useQuery(
    MedicalQuery.getMedDataTransectionsFloatType(daId, puId),
  )

  const handlePrevDataTransaction = (
    prevTransactionData: PreviousDataProps[],
  ): any => {
    if (prevTransactionData.length === 0) return null

    return prevTransactionData[0].value
  }

  useEffect(() => {
    if (transactionData.fetching || !transactionData.data) return
    setPrevData(
      handlePrevDataTransaction(transactionData.data.getMedDataTransactions),
    )
  }, [transactionData])

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        columnSpacing="5px"
        sx={{ marginBottom: '5px' }}
      >
        <Grid item mobile={1}>
          <Icon
            icon="solar:graph-bold"
            className="w-[25px] h-[25px] text-blue-main cursor-pointer"
            onClick={() => setOpenGraphModal(true)}
          />
        </Grid>
        <Grid item mobile>
          <p className="text-start">{title}</p>
        </Grid>
        <Grid item mobile>
          <p>{prevData ? `ค่าก่อนหน้า : ${prevData}` : '-'}</p>
        </Grid>
        <Grid item mobile>
          <p>{`ค่าที่ทำการส่งมา : ${value}`}</p>
        </Grid>
      </Grid>

      <ModalComponent.GraphDiagnoseModal
        title={title}
        unit={unit}
        daId={daId}
        puId={puId}
        showCondtion={showFlag}
        open={openGraphModal}
        onClose={() => setOpenGraphModal(false)}
      />
    </>
  )
}

export function FloatTypeCard({ transaction, puId }: HealthDataProps) {
  //console.log('transaction : ' + JSON.stringify(transaction))
  return (
    <div className="px-[20px] py-[10px]">
      {transaction?.FLOAT?.map((item: any, index: number) => (
        <Transaction
          key={`data-test-${index}`}
          title={item.diseaseAttibute?.medName}
          unit={item.diseaseAttibute?.unitEnglish}
          value={item.value}
          showFlag={item.diseaseAttibute?.showFlag}
          daId={item.diseaseAttibute?.daId}
          puId={puId}
        />
      ))}
    </div>
  )
}

export function TextTypeCard({ transaction, puId }: HealthDataProps) {
  const [optionData, setOptionData] = useState<any[]>([])
  const [optionSelected, setOptionSelected] = useState<string>('')
  const [transectionData, setTransectionData] = useState({
    text: transaction['TEXT'][0].desciption,
  })

  const [pastMedicalData, executePastMedicalData] = useQuery({
    ...MedicalQuery.getPastMedicalTransection(optionSelected),
    pause: true,
  })

  const [historyTransection] = useQuery(
    MedicalQuery.getHistoryMedTransection(
      puId,
      transaction['TEXT'][0].diseaseAttibute.daId,
    ),
  )

  useEffect(() => {
    if (historyTransection?.fetching || !historyTransection?.data) return

    const { getMedDataTransactions } = historyTransection.data
    setOptionData(
      getMedDataTransactions.map((item: any) => ({
        key: item.umtId,
        value: date.convertToShowDateTime(item.createdAt),
      })),
    )
  }, [historyTransection])

  useEffect(() => {
    if (optionSelected !== '') {
      executePastMedicalData()
    }
  }, [optionSelected])

  useEffect(() => {
    if (
      pastMedicalData.fetching ||
      !pastMedicalData.data ||
      pastMedicalData.data === null
    )
      return

    const { getMedDataTransaction } = pastMedicalData.data
    setTransectionData({
      text: getMedDataTransaction?.desciption,
    })
  }, [pastMedicalData.data])

  return (
    <div className="py-[25px] px-[10px]">
      <Formik
        initialValues={{
          date: { key: 'เลือกวันที่ข้อมูล', value: 'เลือกวันที่ข้อมูล' },
        }}
        onSubmit={null}
      >
        {(formik) =>
          optionData.length > 0 && (
            <div className="flex flex-row justify-end mb-[20px]">
              <ElementComponent.SelectBar.SelectBar
                width="w-[230px]"
                height="h-[40px]"
                label="เลือกวันที่ข้อมูล"
                placeholder="เลือกวันที่ข้อมูล"
                name="date"
                option={optionData}
                data={formik.values.date}
                onChange={(value: any) => {
                  formik.setFieldValue('date', value)
                  setOptionSelected(value)
                }}
              />
            </div>
          )
        }
      </Formik>

      {transectionData.text && (
        <div className="pb-[25px]">
          <p className="text-[18px] font-bold mb-[25px]">ข้อมูลเพิ่มเติม</p>
          <p className="text-[16px]">{transectionData.text}</p>
        </div>
      )}
    </div>
  )
}

export function ImageTypeCard({ transaction, puId }: HealthDataProps) {
  const [optionData, setOptionData] = useState<any[]>([])
  const [optionSelected, setOptionSelected] = useState<string>('')
  const [transectionData, setTransectionData] = useState({
    image: transaction['IMAGE'].map((image: any) => image.imagePath),
  })

  const [pastMedicalData, executePastMedicalData] = useQuery({
    ...MedicalQuery.getPastMedicalTransection(optionSelected),
    pause: true,
  })
  const [historyTransection] = useQuery(
    MedicalQuery.getHistoryMedTransection(
      puId,
      transaction['IMAGE'][0].diseaseAttibute.daId,
    ),
  )

  useEffect(() => {
    if (historyTransection?.fetching || !historyTransection?.data) return

    const { getMedDataTransactions } = historyTransection.data
    setOptionData(
      getMedDataTransactions.map((item: any) => ({
        key: item.umtId,
        value: date.convertToShowDateTime(item.createdAt),
      })),
    )
  }, [historyTransection])

  useEffect(() => {
    if (optionSelected !== '') {
      executePastMedicalData()
    }
  }, [optionSelected])

  useEffect(() => {
    if (
      pastMedicalData.fetching ||
      !pastMedicalData.data ||
      pastMedicalData.data === null
    )
      return

    const { getMedDataTransaction } = pastMedicalData.data
    setTransectionData({
      image: [getMedDataTransaction?.imagePath],
    })
  }, [pastMedicalData.data])

  return (
    <div className="py-[25px] px-[10px]">
      <Formik
        initialValues={{
          date: { key: 'เลือกวันที่ข้อมูล', value: 'เลือกวันที่ข้อมูล' },
        }}
        onSubmit={null}
      >
        {(formik) =>
          optionData.length > 0 && (
            <div className="flex flex-row justify-end mb-[20px]">
              <ElementComponent.SelectBar.SelectBar
                width="w-[230px]"
                height="h-[40px]"
                label="เลือกวันที่ข้อมูล"
                placeholder="เลือกวันที่ข้อมูล"
                name="date"
                option={optionData}
                data={formik.values.date}
                onChange={(value: any) => {
                  formik.setFieldValue('date', value)
                  setOptionSelected(value)
                }}
              />
            </div>
          )
        }
      </Formik>

      {transectionData.image && (
        <div className="flex gap-[5px]">
          {transectionData.image?.map((imagePath: string, index: number) => (
            <div key={`test-image-${index}`} className="w-[170px] h-[170px]">
              <ElementComponent.Image
                className="h-full w-full object-cover bg-center rounded-[10px] border"
                src={imagePath}
                alt=""
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const preProcDropdownData = (
  transaction: any,
  puId: string,
  cardFormatType: CardFormatType,
) => {
  const where_condition = {
    puId: {
      equals: puId,
    },
    userMedTrasaction: {
      some: {
        diseaseAttibute: {
          is: {
            OR: [],
          },
        },
      },
    },
  }

  if (cardFormatType === CardFormatType.IMAGE) {
    transaction['IMAGE'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.userMedTrasaction.some.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )
  }

  if (cardFormatType === CardFormatType.TEXT) {
    transaction['TEXT'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.userMedTrasaction.some.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )
  }

  if (cardFormatType === CardFormatType.IMAGE_TEXT) {
    transaction['IMAGE'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.userMedTrasaction.some.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )

    transaction['TEXT'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.userMedTrasaction.some.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )
  }

  return where_condition
}

const preProcCardData = (
  transaction: any,
  umdId: string,
  cardFormatType: CardFormatType,
) => {
  const where_condition = {
    umdId: {
      equals: umdId,
    },
    diseaseAttibute: {
      is: {
        OR: [],
      },
    },
  }

  if (cardFormatType === CardFormatType.IMAGE) {
    transaction['IMAGE'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )
  }

  if (cardFormatType === CardFormatType.TEXT) {
    transaction['TEXT'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )
  }

  if (cardFormatType === CardFormatType.IMAGE_TEXT) {
    transaction['TEXT'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )

    transaction['IMAGE'].map(
      (item: {
        diseaseAttibute: {
          daId: string
        }
      }) => {
        where_condition.diseaseAttibute.is.OR.push({
          daId: {
            equals: item.diseaseAttibute.daId,
          },
        })
      },
    )
  }

  return where_condition
}

export function TextAndImageTypeCard({ transaction, puId }: HealthDataProps) {
  const [cardFormatType, setFormatType] = useState<CardFormatType>(null)
  const [optionData, setOptionData] = useState<any[]>([])
  const [optionSelected, setOptionSelected] = useState<string>('')
  const [transectionData, setTransectionData] = useState<{
    text: {
      name: string
      value: string
    }[]
    image: {
      name: string
      value: string
    }[]
  }>({
    text: transaction['TEXT']
      ? transaction['TEXT']?.map((data: any) => {
          return {
            name: data.diseaseAttibute.medName,
            value: data.desciption,
          }
        })
      : [],
    image: transaction['IMAGE']?.map((data: any) => {
      return {
        name: data.diseaseAttibute.medName,
        value: data.imagePath.split(','),
      }
    }),
  })

  const [historyTransection] = useQuery(
    MedicalQuery.getHistorySpecMedTransaction({
      ...preProcDropdownData(transaction, puId, cardFormatType),
    }),
  )

  const [pastMedicalData, executePastMedicalData] = useQuery({
    ...MedicalQuery.getPastMedicalSpecTransaction(
      preProcCardData(transaction, optionSelected, cardFormatType),
    ),
    pause: true,
  })

  useEffect(() => {
    const keys = Object.keys(transaction)
    if (keys.includes('TEXT') && keys.includes('IMAGE')) {
      setFormatType(CardFormatType.IMAGE_TEXT)
      return
    }

    if (keys.includes('TEXT')) {
      setFormatType(CardFormatType.TEXT)
      return
    }

    setFormatType(CardFormatType.IMAGE)
  }, [])

  useEffect(() => {
    if (historyTransection?.fetching || !historyTransection?.data) return

    const { getMedicalDatas } = historyTransection.data
    setOptionData(
      getMedicalDatas.map((item: any) => ({
        key: item.umdId,
        value: date.convertToShowDateTime(item.createdAt),
      })),
    )
  }, [historyTransection])

  useEffect(() => {
    if (optionSelected !== '') {
      executePastMedicalData()
    }
  }, [optionSelected])

  useEffect(() => {
    if (
      pastMedicalData.fetching ||
      !pastMedicalData.data ||
      pastMedicalData.data === null
    )
      return

    const { getMedDataTransactions } = pastMedicalData.data
    const data_arrgate = _.groupBy(
      getMedDataTransactions,
      'diseaseAttibute.type',
    )

    setTransectionData({
      text: data_arrgate['TEXT']
        ? data_arrgate['TEXT'].map((data: any) => {
            return {
              name: data.diseaseAttibute.medName,
              value: data.desciption,
            }
          })
        : [],
      image: data_arrgate['IMAGE'].map((data: any) => {
        return {
          name: data.diseaseAttibute.medName,
          value: data.imagePath.split(','),
        }
      }),
    })
  }, [pastMedicalData.data])

  return (
    <div className="py-[25px] px-[10px]">
      <Formik
        initialValues={{
          date: { key: 'เลือกวันที่ข้อมูล', value: 'เลือกวันที่ข้อมูล' },
        }}
        onSubmit={null}
      >
        {(formik) =>
          optionData.length > 0 && (
            <div className="flex flex-row justify-end mb-[20px]">
              <ElementComponent.SelectBar.SelectBar
                width="w-[230px]"
                height="h-[40px]"
                label="เลือกวันที่ข้อมูล"
                placeholder="เลือกวันที่ข้อมูล"
                name="date"
                option={optionData}
                data={formik.values.date}
                onChange={(value: any) => {
                  formik.setFieldValue('date', value)
                  setOptionSelected(value)
                }}
              />
            </div>
          )
        }
      </Formik>

      {transectionData.image && (
        <div className="flex gap-[5px]">
          {transectionData.image.map((data: any, index: number) => (
            <div key={`image-${data.name}-${index}`}>
              {data.value.map((imagePath: string) => (
                <div key={`image-${imagePath}`}>
                  <p className="text-[18px] font-bold mb-[15px]">{data.name}</p>
                  <div className="w-[170px] h-[170px]">
                    <ElementComponent.Image
                      className="h-full w-full object-cover bg-center rounded-[10px] border"
                      src={imagePath}
                      alt={`${data.name}_${index}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {transectionData.text?.length > 0 &&
        transectionData.image?.length > 0 && (
          <Divider sx={{ marginY: '40px' }} />
        )}

      {transectionData.text && (
        <>
          {transectionData.text.map((data: any, index: number) => (
            <div className="pb-[25px]" key={`text-${data.name}-${index}`}>
              <p className="text-[18px] font-bold mb-[25px]">{data.name}</p>
              <p className="text-[16px]">{data.value}</p>
            </div>
          ))}
        </>
      )}
    </div>
  )
}
