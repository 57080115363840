import React from 'react'
import { Skeleton, Divider } from '@mui/material'

export default function MedicalPatientCardSkeleton() {
  return (
    <div
      className="px-[20px] py-[35px] mt-[23px] rounded-[10px] bg-white-main"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <Skeleton variant="rectangular" height="45px" width="50%" />
      <Divider sx={{ marginY: '20px' }} />

      <div className="flex flex-col gap-[10px]">
        {Array(4)
          .fill(0)
          .map((_, index: number) => (
            <div
              className="p-[10px] border rounded-[10px]"
              key={`data-card-${index}-loading`}
            >
              <Skeleton variant="rectangular" height="40px" />
            </div>
          ))}
      </div>
    </div>
  )
}
