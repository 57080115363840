import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import { ElementComponent } from 'components'
import { PatientQuery } from 'services/graphql/patientService'
import { useQuery } from 'urql'
import { date } from 'utils'

interface DiagnoseModalProps {
  data: any
  open: boolean
  onClose: Function
}

export default function ConsultModal({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data,
  open,
  onClose,
}: DiagnoseModalProps) {
  const [patientData, setPatientData] = useState<any>({})
  const [patientUserData] = useQuery(
    PatientQuery.getUserByUserId(
      JSON.stringify(data) === '{}' ? '1' : data.uid,
    ),
  )
  useEffect(() => {
    if (patientUserData.fetching || !patientUserData.data) return

    const { getUser } = patientUserData.data
    setPatientData(getUser)
  }, [patientUserData.data])
  return (
    <ElementComponent.ModalForm open={open} onClose={onClose}>
      <div className="w-[980px] py-[40px] px-[25px]">
        <Grid
          container
          justifyContent="center"
          columns={{ desktop: 2.05 }}
          gap="8px"
        >
          <Grid item desktop={0.5}>
            <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
              ชื่อผู้ป่วย
            </p>
          </Grid>
          <Grid item desktop={1.5}>
            <p className="text-[16px] w-[100%] p-[4px] ">
              {JSON.stringify(data) === '{}'
                ? ''
                : data.user.firstname + ' ' + data.user.lastname}{' '}
              {' อายุ '}
              {!patientUserData.fetching &&
                !patientUserData.error &&
                date.getAge(
                  patientData?.UserMedicalDemograhicData?.birthdate,
                )}{' '}
              ปี
            </p>
          </Grid>
          <Grid item desktop={0.5}>
            <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
              โรคประจำตัว
            </p>
          </Grid>
          <Grid item desktop={1.5}>
            <p className="text-[16px] w-[100%] p-[4px] ">
              {patientData?.UserMedicalDemograhicData
                ?.userUnderlyingDiseaseTrasaction?.length > 0
                ? patientData?.UserMedicalDemograhicData?.userUnderlyingDiseaseTrasaction
                    .map((item: any) => item.underlyingDisease.thaiName)
                    .join(',')
                : 'ไม่มี'}
            </p>
          </Grid>
          <Grid item desktop={0.5}>
            <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
              หน่วยงานที่ดูแล
            </p>
          </Grid>
          <Grid item desktop={1.5}>
            <p className="text-[16px] w-[200px] p-[4px] ">DR. ASA</p>
          </Grid>
          <Grid item desktop={0.5}>
            <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
              แพทย์ประจำตัว
            </p>
          </Grid>
          <Grid item desktop={1.5}>
            <p className="text-[16px] w-[200px] p-[4px] ">ธนกร ยนต์นิยม</p>
          </Grid>
          <Grid item desktop={0.5}>
            <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
              ปรึกษาเรื่อง
            </p>
          </Grid>
          <Grid item desktop={1.5}>
            <p className="text-[16px] w-[100%] p-[4px] ">
              {JSON.stringify(data) === '{}' ? '' : data.consultType.typeName}
            </p>
          </Grid>
          <Grid item desktop={0.5}>
            <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
              อาการเบื้องต้น
            </p>
          </Grid>
          <Grid item desktop={1.5}>
            <p className="text-[16px] w-[100%] p-[4px] ">
              {JSON.stringify(data) === '{}' ? '' : data.textConsult}
            </p>
          </Grid>
          <Grid item desktop={0.5}>
            <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
              แพ้ยา/แพ้อาหาร
            </p>
          </Grid>
          <Grid item desktop={1.5}>
            <p className="text-[16px] w-[100%] p-[4px] ">
              {patientData?.UserMedicalDemograhicData?.allergic}
            </p>
          </Grid>
        </Grid>
      </div>
    </ElementComponent.ModalForm>
  )
}
