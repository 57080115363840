import React, { useRef } from 'react'
import { Button } from '@mui/material'
import * as SVG from 'assets/svg'

interface ImageButtonProps {
  isLoading?: boolean
  id: string
  text?: string
  width?: string
  height?: string
  onChange: Function
}

function ImageButton({
  isLoading = false,
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  text = '',
  width = '260px',
  height = '210px',
  onChange,
}: ImageButtonProps) {
  const uploadRef = useRef(null)

  return (
    <>
      <input
        ref={uploadRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={(event) => onChange(event)}
      />
      <Button
        disabled={isLoading}
        id={id}
        variant="text"
        sx={{ width: width, height: height }}
        onClick={() => uploadRef.current.click()}
      >
        <div className="flex flex-col justify-center items-center">
          {/*<Icon icon="ic:baseline-camera-alt" className="text-[25px]" />
          <p>{text ?? 'เพิ่มรูปภาพ'}</p>*/}
          <SVG.BtnImageAdd />
        </div>
      </Button>
    </>
  )
}

export default ImageButton
