import React from 'react'
//import { useQuery } from 'urql'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'
import { date } from 'utils'
//import { SkeletonComponent } from 'components'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
)

function LineChartUser() {
  const preProcessData = () => {
    return {
      labels: ['06/09/57', '06/09/57', '06/09/57', '06/09/57', '06/09/57'],
      datasets: [
        {
          label: 'ผู้ใช้งานต่อวัน',
          data: [100, 120, 130, 100, 90, 150],
          pointBorderColor: '#0036F6',
          borderColor: '#0036F6',
          fill: true,
        },
        {
          label: 'ผู้ใช้งานใหม่',
          data: [50, 60, 90, 13, 56, 19],
          pointBorderColor: '#5BC5EF',
          borderColor: '#5BC5EF',
          fill: true,
        },
      ],
    }
  }

  const renderGraph = () => {
    /*if (data.length === 0) {
      return (
        <div className="w-full min-h-[300px] flex justify-center items-center">
          <p className="text-gray-main">ไม่มีข้อมูล</p>
        </div>
      )
    }*/
    const plugin = {
      id: 'customCanvasBackgroundColor',
      beforeDraw: (chart) => {
        const { ctx } = chart

        ctx.save()
        ctx.restore()
      },
    }

    return (
      <div className="h-[270px] w-full">
        <Line
          plugins={[plugin]}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                align: 'end',
              },
              // Cannot use unitThai in y label because still not figure out how to rotate it
              title: {
                display: true,
                text: 'จำนวนผู้ใช้งาน',
                align: 'start',
              },
              tooltip: {
                callbacks: {
                  title: function (context) {
                    return date.convertToShowDateForGraph(
                      new Date(context[0].label),
                    )
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  color: 'black',
                  display: true,
                  text: 'วันที่',
                  align: 'end',
                },
                ticks: {
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function (val) {
                    // Hide every 2nd tick label
                    return date.convertToShowDateCommonEra(
                      date.convertStringToDate(
                        this.getLabelForValue(val as number),
                      ),
                    )
                  },
                },
              },
            },
          }}
          redraw={true}
          data={preProcessData()}
        />
      </div>
    )
  }

  return (
    <div className="h-full">
      <>
        <div className="flex items-center gap-[5px]">
          <span className="font-bold">ผู้ใช้งาน</span>
          {/*<span className="text-black-lighter text-[13px]">จำนวนผู้ใช้งาน</span>*/}
        </div>
        <hr className="mt-[10px] mb-[20px]" />
        {renderGraph()}
      </>
    </div>
  )
}

export default LineChartUser
