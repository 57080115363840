export const getMedTransaction = (
  condition: Object,
  take: number,
  skip: number,
  not_diagnose: boolean,
) => {
  const medicalDataSchema = `query getMedicalDatas($where: UserMedicalDataWhereInput, $take: Int, $skip: Int, $orderBy: [UserMedicalDataOrderByWithRelationInput!]) {
                              getMedicalDatas(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
                                umdId
                                patientUser {
                                  hnId
                                  firstname
                                  lastname
                                }
                                createdAt
                              }
                            }`

  const medicalDataWithDiagnoseSchema = `query getMedicalDatas($where: UserMedicalDataWhereInput, $take: Int, $skip: Int, $orderBy: [UserMedicalDataOrderByWithRelationInput!]) {
                              getMedicalDatas(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
                                umdId
                                patientUser {
                                  hnId
                                  firstname
                                  lastname
                                }
                                diagnosisData {
                                  createdAt
                                  updatedAt
                                  allowedUpdateFlag
                                }
                                createdAt
                              }
                            }`

  return {
    query: not_diagnose ? medicalDataSchema : medicalDataWithDiagnoseSchema,
    variables: {
      where: {
        ...condition,
        patientUser: {
          is: {},
        },
      },
      take: take,
      skip: skip,
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}

export const getMedTransactionPagingCount = (condition: Object) => {
  const getAggregateMedicalData = `
      query getAggregateMedicalData($where: UserMedicalDataWhereInput, $_count: UserMedicalDataCountAggregateInput) {
        getAggregateMedicalData(where: $where, _count: $_count) {
            _count {
                umdId
            }
        }
      }
    `

  return {
    query: getAggregateMedicalData,
    variables: {
      where: {
        ...condition,
        patientUser: {
          is: {},
        },
      },
      _count: {
        umdId: true,
      },
    },
  }
}

export const getMedicalDatasPage = (diagnose: string) => {
  const getMedicalDataPageSchema = `
    query getMedicalData($where: UserMedicalDataWhereInput) {
      getMedicalData(where: $where) {
        umdId
        puId
        dataInputFlag
        userMedTrasaction {
          umtId
          diseaseAttibute {
            daId
            showFlag
            disease {
              dId
              medName
              type
            }
            medName
            type
            unitEnglish
          }
          value
          desciption
          imagePath
        }
        patientUser {
            uId
            prefix
            firstname
            lastname
            hnId
            UserMedicalDemograhicData {
                gender
                birthdate
                allergic
                userUnderlyingDiseaseTrasaction {
                    uudtId
                    underlyingDisease {
                        udId
                        medName
                    }
                }
            }
        }
        diagnosisData {
          ddId
          suggestion
          diagnosisResult
          description
        }
        createdAt
      }
    }
  `

  return {
    query: getMedicalDataPageSchema,
    variables: {
      where: {
        umdId: {
          equals: diagnose,
        },
      },
    },
  }
}

export const getMedDataTransectionsFloatType = (daId: string, puId: string) => {
  const medTransection = `
    query getMedDataTransactions($where: UserMedTrasactionWhereInput, $take: Int, $skip: Int, $orderBy: [UserMedTrasactionOrderByWithRelationInput!]) {
      getMedDataTransactions(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
        umtId
        value
      }
    }
  `

  return {
    query: medTransection,
    variables: {
      where: {
        daId: {
          equals: daId,
        },
        userMedicalData: {
          is: {
            puId: {
              equals: puId,
            },
            dataInputFlag: {
              equals: true,
            },
          },
        },
        diseaseAttibute: {
          is: {
            type: {
              equals: 'FLOAT',
            },
          },
        },
      },
      take: 1,
      skip: 1,
      orderBy: {
        createdAt: 'desc',
      },
    },
  }
}

export const getMedDataTransectionsFloatTypeWithMedName = (
  medName: string,
  puId: string,
) => {
  const medTransection = `
    query getMedDataTransactions($where: UserMedTrasactionWhereInput, $take: Int, $skip: Int, $orderBy: [UserMedTrasactionOrderByWithRelationInput!]) {
      getMedDataTransactions(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
        umtId
        value
      }
    }
  `

  return {
    query: medTransection,
    variables: {
      where: {
        medName: {
          equals: medName,
        },
        userMedicalData: {
          is: {
            puId: {
              equals: puId,
            },
            dataInputFlag: {
              equals: true,
            },
          },
        },
        diseaseAttibute: {
          is: {
            type: {
              equals: 'FLOAT',
            },
          },
        },
      },
      take: 1,
      skip: 1,
      orderBy: {
        createdAt: 'desc',
      },
    },
  }
}

export const getUnderlyingDisease = () => {
  const underlyingDiseaseSchema = `
    query {
      getUnderlyingDiseases{
        udId
        medName
      }
    }
  `

  return {
    query: underlyingDiseaseSchema,
  }
}

export const getHistorySpecMedTransaction = (where: any) => {
  const historyMedSchema = `
    query getMedicalDatas($where: UserMedicalDataWhereInput, $orderBy: [UserMedicalDataOrderByWithRelationInput!]) {
      getMedicalDatas(where: $where, orderBy: $orderBy) {
          umdId
          createdAt
      }
    }
  `

  return {
    query: historyMedSchema,
    variables: {
      where: {
        ...where,
      },
      orderBy: {
        createdAt: 'desc',
      },
    },
  }
}

export const getHistoryMedTransection = (
  puId: string,
  daId: string,
  type?: string | string[],
) => {
  const historyMedSchema = `
    query getMedDataTransactions($where: UserMedTrasactionWhereInput, $orderBy: [UserMedTrasactionOrderByWithRelationInput!]) {
      getMedDataTransactions(where: $where, orderBy: $orderBy) {
        umtId
        value
        createdAt
      }
    }
  `

  return {
    query: historyMedSchema,
    variables: {
      where: {
        daId: {
          equals: daId,
        },
        userMedicalData: {
          is: {
            puId: {
              equals: puId,
            },
            dataInputFlag: {
              equals: true,
            },
          },
        },
        diseaseAttibute: {
          is: {
            type: {
              in: type ? type : ['TEXT', 'IMAGE'],
            },
          },
        },
      },
      orderBy: {
        createdAt: 'desc',
      },
    },
  }
}

export const getDiseaseAttibutesCondition = (daId: string, puId: string) => {
  const querySchema = `query getDiseaseAttibuteCondition($where: DiseaseAttibuteWhereInput) {
                        getDiseaseAttibuteCondition(where: $where) {
                          daId
                          condition
                          showFlag
                        }
                      }`

  return {
    query: querySchema,
    variables: {
      where: {
        daId: {
          equals: daId,
        },
        uId: {
          equals: puId,
        },
      },
    },
  }
}

export const getPastMedicalSpecTransaction = (where: any) => {
  const pastMedicalSchema = `
    query getMedDataTransactions($where: UserMedTrasactionWhereInput) {
      getMedDataTransactions(where: $where) {
        umtId
        diseaseAttibute {
            daId
            medName
            type
        }
        desciption
        imagePath
      }
    }
  `

  return {
    query: pastMedicalSchema,
    variables: {
      where: where,
    },
  }
}

export const getPastMedicalTransection = (umtId: string) => {
  const pastMedicalSchema = `
    query getMedDataTransaction($where: UserMedTrasactionWhereInput) {
      getMedDataTransaction(where: $where) {
        umtId
        desciption
        imagePath
        createdAt
      }
    }
  `

  return {
    query: pastMedicalSchema,
    variables: {
      where: {
        umtId: {
          equals: umtId,
        },
      },
    },
  }
}

export const getPastMedicalTransectionSpec = (umtId: string) => {
  const pastMedicalSchema = `
    query getMedDataTransaction($where: UserMedTrasactionWhereInput) {
      getMedDataTransaction(where: $where) {
        umtId
        desciption
        imagePath
        createdAt
      }
    }
  `

  return {
    query: pastMedicalSchema,
    variables: {
      where: {
        umtId: {
          equals: umtId,
        },
      },
    },
  }
}

export const getDiagosisData = (puId: string, take: number, skip: number) => {
  const pastMedicalSchema = `
    query getDiagnosisDatas($where: DiagnosisDataWhereInput!, $orderBy: [DiagnosisDataOrderByWithRelationInput!], $take: Int, $skip: Int) {
      getDiagnosisDatas(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            ddId
            diagnosisResult
            description
            suggestion
            doctorUser {
                prefix
                firstname
                lastname
            }
            updatedAt
      }
    }
  `

  return {
    query: pastMedicalSchema,
    variables: {
      where: {
        patientUser: {
          is: {
            uId: {
              equals: puId,
            },
          },
        },
      },
      orderBy: {
        updatedAt: 'desc',
      },
      take: take,
      skip: skip,
    },
  }
}

export const getLastDiagosisResult = (umdId: string, take: number) => {
  const pastMedicalSchema = `
    query getDiagnosisDatas($where: DiagnosisDataWhereInput!, $orderBy: [DiagnosisDataOrderByWithRelationInput!], $take: Int, $skip: Int) {
      getDiagnosisDatas(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            ddId
            diagnosisResult
            description
            suggestion
            doctorUser {
                prefix
                firstname
                lastname
            }
            updatedAt
      }
    }
  `

  return {
    query: pastMedicalSchema,
    variables: {
      where: {
        umdId: {
          equals: umdId,
        },
      },
      orderBy: {
        updatedAt: 'desc',
      },
      take: take,
    },
  }
}

export const countDiagnosisDatas = (puId: string) => {
  const pastMedicalSchema = `
    query aggregateDiagnosisDatas($where: DiagnosisDataWhereInput!, $_count: DiagnosisDataCountAggregateInput) {
      aggregateDiagnosisDatas(where: $where, _count: $_count){
        _count {
          ddId
        }
      }
    }
    `

  return {
    query: pastMedicalSchema,
    variables: {
      where: {
        patientUser: {
          is: {
            uId: {
              equals: puId,
            },
          },
        },
      },
      _count: {
        ddId: true,
      },
    },
  }
}

export const getHealthStatusDiseaseScorcesForDoctor = (
  disease_dId: string,
  puId: string,
) => {
  const getHealthStatusDiseaseScorces = `query getHealthStatusDiseaseScorcesForDoctor($where: HealthStatusDiseaseScorceDataWhereInput! $orderBy: [HealthStatusDiseaseScorceDataOrderByWithRelationInput!], $take: Int){
    getHealthStatusDiseaseScorcesForDoctor(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getHealthStatusDiseaseScorces,
    variables: {
      where: {
        puId: {
          equals: puId,
        },
        disease: {
          is: {
            medName: {
              equals: disease_dId,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 1,
    },
  }
}

export const getOneDiseaseData = (medName: string) => {
  const query = `
    query getDiseases($where: DiseaseWhereInput){
      getDiseases(where: $where) {
        dId
        engName
        thaiName
        medName
        imagePath
        diseaseAttibute {
            daId
            type
            engName
            thaiName
            unitThai
            medName
            description
            condition
            inputFlag
            calFlag
            showFlag
            mandatoryFlag
        }
        associateDiease {
            adId
            thaiName
            imagePath
            associateDieaseAttibute {
                adaId
                medName
                thaiName
                unitThai
                description
                type
                indexSelect
                condition
                showFlag
                showPrevoius
            }
        }
        type
      }
    }
  `

  return {
    query: query,
    variables: {
      where: {
        medName: {
          equals: medName,
        },
      },
    },
  }
}

export const getLatestMedDataTransactionByadaID = (
  adaId: string,
  puId: string,
) => {
  const getMedDataTransactions = `query getMedDataTransactions($where: UserMedTrasactionWhereInput! $orderBy: [UserMedTrasactionOrderByWithRelationInput!], $take: Int){
    getMedDataTransactions(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getMedDataTransactions,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: puId,
            },
          },
        },
        adaId: {
          equals: adaId,
        },
        associateDieaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 1,
    },
  }
}

export const getMedDataTransactionsByadaId = (adaId: string, puId: string) => {
  const getMedDataTransactions = `query getMedDataTransactionsForDoctor($where: UserMedTrasactionWhereInput! $orderBy: [UserMedTrasactionOrderByWithRelationInput!], $take: Int){
    getMedDataTransactionsForDoctor(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getMedDataTransactions,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: puId,
            },
          },
        },
        adaId: {
          equals: adaId,
        },
        associateDieaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 6,
    },
  }
}

export const getAssociateDiseaseAttibuteCondition = (adaId: string) => {
  const querySchema = `query getAssociateDiseaseAttibuteCondition($where: AssociateDieaseAttibuteWhereInput) {
    getAssociateDiseaseAttibuteCondition(where: $where) {
      adaId
      condition
    }
  }`

  return {
    query: querySchema,
    variables: {
      where: {
        adaId: {
          equals: adaId,
        },
      },
    },
  }
}

export const getIcdTenListWithCode = (icdCode: string) => {
  const querySchema = `query getIcdTenList ($where: IcdTenWhereInput) {
    getIcdTenList (where: $where) {
        icdCode
        icdCodePair
        icdLevel
        icdName
        icdType
    }
}`

  return {
    query: querySchema,
    variables: {
      where: {
        OR: [
          {
            icdCode: {
              contains: icdCode,
            },
          },
          {
            icdName: {
              contains: icdCode,
            },
          },
        ],
        icdType: {
          equals: 'TRUE',
        },
      },
    },
  }
}

export const getDrugListWithName = (dName: string) => {
  const querySchema = `query getDrugList ($where: DrugWhereInput) {
    getDrugList (where: $where) {
        duId
        dName
        description
        cost
        income
        stock
        drugDefaultCondition
        phrDrug
        quantity
        unit
        typeOne
        dUseType
        invoiceType
        injDefault
        injMin
        injMax
        injUnit
        injIM
        injIV
        injSC
        tradeName
    }
}`

  return {
    query: querySchema,
    variables: {
      where: {
        dName: {
          contains: dName,
        },
      },
    },
  }
}
