// Login Pages
const LOGIN_PAGE_URL = '/login'

// User Management Pages
const SETTING_PAGE_URL = '/setting'
const PROFILE_PAGE_URL = `${SETTING_PAGE_URL}/profile`
const CHANGE_PASSWORD_PAGE_URL = `${SETTING_PAGE_URL}/change-password`

// Overview Pages
const OVERVIEW_PAGE_URL = '/overview'

const PATIENT_PAGE_URL = '/patient'
const PATIENT_DATA_PAGE_URL = '/patient/:patientId'
const DIAGNOSE_PAGE_URL = '/diagnose'
const DIAGNOSE_DATA_PAGE_URL = '/diagnose/:diagnoseId'

const OVERAll_DATA_PAGE_URL = '/overall/:patientId/:cId'
const OVERAll_PAGE_URL = '/overall'

const DIAGNOSES_PAGE_URL = '/diagnoses'
const DIAGNOSES_DATA_PAGE_URL = '/diagnoses/:patientId/:cId'
const MEDICAL_PAGE_URL = '/medical'
const MEDICAL_DATA_PAGE_URL = '/medical/:patientId/:cId'
const INPUT_PAGE_URL = '/input'
const INPUT_DATA_PAGE_URL = '/input/:patientId/:cId'

const DISEASE_PAGE_URL = '/disease'
const DISEASE_DATA_PAGE_URL = '/disease/:medIndex/:patientId/:cId'

// ETC
const EXPECT_PAGE_URL = '*'

export {
  DIAGNOSES_DATA_PAGE_URL,
  MEDICAL_DATA_PAGE_URL,
  INPUT_DATA_PAGE_URL,
  DISEASE_PAGE_URL,
  OVERAll_DATA_PAGE_URL,
  OVERAll_PAGE_URL,
  DIAGNOSES_PAGE_URL,
  MEDICAL_PAGE_URL,
  INPUT_PAGE_URL,
  LOGIN_PAGE_URL,
  CHANGE_PASSWORD_PAGE_URL,
  EXPECT_PAGE_URL,
  PROFILE_PAGE_URL,
  OVERVIEW_PAGE_URL,
  SETTING_PAGE_URL,
  PATIENT_PAGE_URL,
  PATIENT_DATA_PAGE_URL,
  DIAGNOSE_PAGE_URL,
  DIAGNOSE_DATA_PAGE_URL,
  DISEASE_DATA_PAGE_URL,
}
