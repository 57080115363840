/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Divider } from '@mui/material'
import { Icon } from '@iconify/react'

import { ElementComponent } from 'components'

interface ResultDiagnoseModalProps {
  data: any
  open: boolean
  onClose: Function
}

export default function ResultDiagnoseModal({
  data,
  open,
  onClose,
}: ResultDiagnoseModalProps) {
  return (
    <ElementComponent.ModalForm open={open} onClose={onClose}>
      <div className="w-[980px] py-[40px] px-[25px]">
        <div className="flex flex-row items-center gap-[10px]">
          <Icon
            icon="solar:document-medicine-bold"
            className="w-[35px] h-[35px]"
          />
          <p className="font-bold text-[24px]">ประวัติการวินิจฉัย</p>
        </div>
        <Divider sx={{ marginY: '20px' }} />

        <div className="mt-[15px]">
          <div className="mt-[28px] flex flex-col gap-[20px]">
            <p className="text-[20px] font-bold">ผลลัพธ์การวินิจฉัย</p>
            <p className="text-[16px]">{data?.diagnosisResult}</p>
          </div>
          <div className="mt-[28px] flex flex-col gap-[20px]">
            <p className="text-[20px] font-bold">
              รายละเอียดการวินิจฉัยเพิ่มเติม
            </p>
            <p className="text-[16px]">{data?.description}</p>
          </div>
        </div>
      </div>
    </ElementComponent.ModalForm>
  )
}
