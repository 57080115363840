import React from 'react'
import { Skeleton, Divider } from '@mui/material'

export default function DiagnosePatientCardSkeleton() {
  return (
    <div
      className="px-[20px] py-[35px] mt-[23px] rounded-[10px] bg-white-main"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <Skeleton variant="rectangular" height="45px" width="50%" />
      <Divider sx={{ marginY: '20px' }} />

      <div className="flex flex-col gap-[10px]">
        <Skeleton variant="rectangular" height="45px" />
        <Skeleton variant="rectangular" height="100px" />
        <Skeleton variant="rectangular" height="300px" />
      </div>
    </div>
  )
}
