import React from 'react'

import { Grid } from '@mui/material'
import { ChartComponent } from 'components'

interface INormalDiseaseCardProps {
  diseaseData: {
    imagePath: string
    medName: string
    thaiName: string
    diseaseAttibute: {
      daId: string
      thaiName: string
      description: string
      unitThai: string
      showFlag: boolean
    }[]
  }
  puId: string
}

function NormalDiseaseCard({ diseaseData, puId }: INormalDiseaseCardProps) {
  return (
    <Grid
      container
      justifyContent="center"
      columns={{ desktop: 2.2 }}
      gap="8px"
    >
      {diseaseData.diseaseAttibute.map((item) => {
        return (
          <>
            {item.showFlag && (
              <Grid desktop={1} justifyContent="center">
                <div className=" bg-zinc-100 rounded-[20px] p-[16px] ">
                  <div className="gap-[18px] w-[100%] laptop:w-[100%]">
                    <ChartComponent.LineChartMedicalData
                      puId={puId}
                      daId={item.daId}
                      thaiName={item.thaiName}
                      description={item.description}
                      unitThai={item.unitThai}
                    />
                  </div>
                </div>
              </Grid>
            )}
          </>
        )
      })}
    </Grid>
  )
}

export default NormalDiseaseCard
