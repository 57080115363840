import React, { useState, useEffect } from 'react'
import { Divider, Collapse, Stack } from '@mui/material'
import { Icon } from '@iconify/react'
import { Formik } from 'formik'
import _ from 'lodash'

import { CardComponent, ElementComponent } from 'components'

interface MedicalTransectionProps {
  transaction: any[]
  puId: string
}

interface DataCardComponentProps {
  transectionData: any[]
  puId: string
}

interface DataCradProps {
  title: string
  data: React.ReactNode
}

function DataCrad({ title, data }: DataCradProps) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className="p-[10px] border rounded-[5px]">
      <div
        className="flex items-center justify-between bg-gray-light rounded-[5px] p-[12px] cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <p className="font-bold">{title}</p>
        <Icon
          className="w-[20px] h-[20px]"
          icon="material-symbols:keyboard-arrow-down-rounded"
        />
      </div>
      <Collapse in={open}>{data}</Collapse>
    </div>
  )
}

function GeneralDataCardComponent({
  transectionData,
  puId,
}: DataCardComponentProps) {
  const handleTransectionByType = (transaction: any[], type: string): any => {
    return transaction
      .filter((medTrans: any) => medTrans?.diseaseAttibute?.type === type)
      .reduce(
        (result: any, item: any) => ({
          [type]: [...(result[type] ?? []), item],
        }),
        {},
      )
  }

  return (
    <Stack direction="column" gap="10px">
      {_.size(handleTransectionByType(transectionData, 'FLOAT').FLOAT) ? (
        <DataCrad
          title="ข้อมูลทั่วไป"
          data={
            <CardComponent.HealthDataCard.FloatTypeCard
              transaction={handleTransectionByType(transectionData, 'FLOAT')}
              puId={puId}
            />
          }
        />
      ) : (
        ''
      )}

      {_.size(handleTransectionByType(transectionData, 'TEXT').TEXT) ? (
        <DataCrad
          title="รายละเอียดอาการ"
          data={
            <CardComponent.HealthDataCard.TextTypeCard
              transaction={handleTransectionByType(transectionData, 'TEXT')}
              puId={puId}
            />
          }
        />
      ) : (
        ''
      )}

      {_.size(handleTransectionByType(transectionData, 'IMAGE').IMAGE) ? (
        <DataCrad
          title="รูปภาพเพิ่มเติม"
          data={
            <CardComponent.HealthDataCard.ImageTypeCard
              transaction={handleTransectionByType(transectionData, 'IMAGE')}
              puId={puId}
            />
          }
        />
      ) : (
        ''
      )}
    </Stack>
  )
}

function SpecificDataCardComponent({
  transectionData,
  puId,
}: DataCardComponentProps) {
  const placholderSearch = { key: 'ค้นหา', value: 'ค้นหา' }
  const [transactions, setTransactions] = useState<any>({})
  const [transecOption, setTransecOption] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      if (transectionData?.length && transectionData) {
        const groupDisease = _.groupBy(
          transectionData,
          'diseaseAttibute.disease.medName',
        )
        const groupType = await Object.keys(groupDisease).reduce(
          (result: any, topic: string) => {
            result[topic] = _.groupBy(
              groupDisease[topic],
              'diseaseAttibute.type',
            )
            return result
          },
          {},
        )

        setTransactions(groupType)
      }
    })()
  }, [transectionData])

  const handleSpecificOption = (transection: Object): any[] => {
    return Object.keys(transection).map((topic: string) => ({
      key: topic,
      value: topic,
    }))
  }

  return (
    <DataCrad
      title="ข้อมูลเฉพาะทาง"
      data={
        <div>
          {!_.isEmpty(transactions) && (
            <Formik
              initialValues={{
                specific: null,
              }}
              onSubmit={null}
            >
              {(formik) => {
                return (
                  <div className="p-[16px] flex flex-row justify-end">
                    <ElementComponent.SearchBar
                      option={[
                        placholderSearch,
                        ...handleSpecificOption(transactions),
                      ]}
                      data={formik.values.specific}
                      isSubmitting={false}
                      height="50px"
                      width="300px"
                      name="specific"
                      label=""
                      onSelect={(options: any) => {
                        setTransecOption(options)
                        formik.setFieldValue('specific', options)
                      }}
                    />
                  </div>
                )
              }}
            </Formik>
          )}

          {(transecOption === null
            ? Object.keys(transactions)
            : Object.keys(transactions).filter(
                (topic: string) => topic === transecOption,
              )
          )?.map((topic: string, index: number) => (
            <div key={`specific-card-${index}`} className="mt-[10px]">
              <DataCrad
                title={topic}
                data={
                  <div>
                    {_.has(transactions[topic], 'FLOAT') ? (
                      <CardComponent.HealthDataCard.FloatTypeCard
                        key={`specific-card-${index}`}
                        transaction={transactions[topic]}
                        puId={puId}
                      />
                    ) : (
                      <CardComponent.HealthDataCard.TextAndImageTypeCard
                        key={`specific-card-${index}`}
                        transaction={transactions[topic]}
                        puId={puId}
                      />
                    )}
                  </div>
                }
              />
            </div>
          ))}
        </div>
      }
    />
  )
}

export default function MedicalTransection({
  transaction,
  puId,
}: MedicalTransectionProps) {
  const [transectionGeneral, setTransectionGeneral] = useState<any[]>([])
  const [transactioSpecific, setTransactionspecific] = useState<any[]>([])

  useEffect(() => {
    ;(() => {
      if (transaction?.length) {
        setTransectionGeneral(
          transaction.filter(
            (item: any) => item.diseaseAttibute?.disease?.type === 'GENERAL',
          ),
        )
        setTransactionspecific(
          transaction.filter(
            (item: any) => item.diseaseAttibute?.disease?.type === 'SPECIFIC',
          ),
        )
      }
    })()
  }, [transaction])

  return (
    <div
      className="px-[20px] py-[35px] mt-[23px] rounded-[10px] bg-white-main"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <p className="text-[20px] font-bold">ข้อมูลสุขภาพ</p>
      <Divider sx={{ marginTop: '18px', marginBottom: '25px' }} />

      <Stack direction="column" gap="10px">
        <GeneralDataCardComponent
          transectionData={transectionGeneral}
          puId={puId}
        />

        {Boolean(transactioSpecific.length) && (
          <SpecificDataCardComponent
            transectionData={transactioSpecific}
            puId={puId}
          />
        )}
      </Stack>
    </div>
  )
}
