/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Skeleton, Stack } from '@mui/material'
import { Navbar } from 'components/navbar'
import { route } from 'settings'
import * as SVG from 'assets/svg'
import * as Pics from 'assets/picture'
import { ElementComponent, ModalComponent } from 'components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/bundle'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { useEffect, useState } from 'react'
import { PatientQuery } from 'services/graphql/patientService'
import { date } from 'utils'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'
import { useUser } from 'stores/recoil'
function OverAllPage() {
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const { patientId } = useParams()
  const { cId } = useParams()
  const categorysList: {
    title: string
    imgName: string
    url: string
    medName: string
  }[] = [
    {
      title: 'ข้อมูลผู้ป่วย',
      imgName: Pics.Item0,
      url: `${route.OVERAll_PAGE_URL}/${patientId}/${cId}`,
      medName: '',
    },
    {
      title: 'เบาหวาน',
      imgName: Pics.Item1,
      url: `${route.DISEASE_PAGE_URL}/1/${patientId}/${cId}`,
      medName: 'Diabetes mellitus (DM)',
    },
    {
      title: 'ความดัน',
      imgName: Pics.Item2,
      url: `${route.DISEASE_PAGE_URL}/2/${patientId}/${cId}`,
      medName: 'Hypertension',
    },
    {
      title: 'ไขมัน',
      imgName: Pics.Item3,
      url: `${route.DISEASE_PAGE_URL}/3/${patientId}/${cId}`,
      medName: 'Dyslipidemia',
    },
    {
      title: 'ตับ',
      imgName: Pics.Item4,
      url: `${route.DISEASE_PAGE_URL}/4/${patientId}/${cId}`,
      medName: 'Chronic Liver Disease/Cirrhosis (CLD)',
    },
    {
      title: 'ไต',
      imgName: Pics.Item5,
      url: `${route.DISEASE_PAGE_URL}/5/${patientId}/${cId}`,
      medName: 'Chronic Kidney Disease (CKD)',
    },
    {
      title: 'หัวใจ',
      imgName: Pics.Item6,
      url: `${route.DISEASE_PAGE_URL}/6/${patientId}/${cId}`,
      medName: '',
    },
  ]
  //const [diseaseData, setDiseaseData] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModal, setOpenModal] = useState<boolean>(false)

  const [isLoadedPatientMedical, setIsLoadedPatientMedical] =
    useState<boolean>(false)

  //const [patientDisease] = useQuery(PatientQuery.getUserDiseaseData())
  const [patientUserData] = useQuery(PatientQuery.getUserByUserId(patientId))
  const [patientData, setPatientData] = useState<any>({})
  const [patientDiagnoseLastOneData, setPatientDiagnoseLastOne] = useState([])
  const [dateIcd, setDateIcd] = useState<string>('-')
  const [patientDiagnoseLastOne] = useQuery(
    PatientQuery.getDiagnoseLastOne(patientId),
  )
  const [patientConsultData] = useQuery(PatientQuery.getConsultOne(patientId))
  const [heightVal, setHeightVal] = useState<string>('-')
  const [weightVal, setWeightVal] = useState<string>('-')
  const [bmiVal, setBmiVal] = useState<string>('-')
  const [sbpVal, setSbpVal] = useState<string>('-')
  const [dbpVal, setDbpVal] = useState<string>('-')
  const [step, setStep] = useState<number>(0)
  const handleDiseaseData = (diseaseData: any[], data: any[]): any[] => {
    const result = diseaseData.reduce((result: any[], item: any) => {
      const medicaltransection: any = data.find(
        (medicalData: any) => medicalData.dId === item.dId,
      )

      return [
        ...result,
        ...medicaltransection.diseaseAttibute.flatMap((disease: any) => {
          if (disease.userMedTrasaction.length > 0) {
            return {
              diseaseAttibute: {
                daId: disease.daId,
                showFlag: item.showFlag,
                medName: disease.medName,
                type: disease.type,
                unitEnglish: disease.unitEnglish,
                disease: {
                  dId: item.dId,
                  medName: item.medName,
                  type: item.type,
                },
              },
              umtId: disease.userMedTrasaction[0]?.umtId ?? '',
              value: disease.userMedTrasaction[0]?.value ?? null,
              desciption: disease.userMedTrasaction[0]?.desciption ?? null,
              imagePath: disease.userMedTrasaction[0]?.imagePath ?? '',
            }
          } else return []
        }),
      ]
    }, [])
    return result
  }
  const handleTransectionByType = (transaction: any[], type: string): any => {
    return transaction
      .filter((medTrans: any) => medTrans?.diseaseAttibute?.type === type)
      .reduce(
        (result: any, item: any) => ({
          [type]: [...(result[type] ?? []), item],
        }),
        {},
      )
  }

  useEffect(() => {
    if (!patientDiagnoseLastOne.fetching && !patientDiagnoseLastOne.error) {
      if (patientDiagnoseLastOne.data.getDiagnoseLastOne !== null) {
        setPatientDiagnoseLastOne(
          JSON.parse(patientDiagnoseLastOne.data.getDiagnoseLastOne.icdData),
        )
        setDateIcd(
          patientDiagnoseLastOne.data.getDiagnoseLastOne.createdAt.split(
            'T',
          )[0],
        )
      }
    }

    /*if (
      !patientUserData.fetching &&
      !patientUserData.error &&
      !patientDisease.fetching &&
      !patientDisease.error
    ) {
      //alert(JSON.stringify(patientDisease.data))
      if (!isLoadedPatientMedical) {
        alert(JSON.stringify(patientDisease))
        const [patientMedical] = useQuery(
          PatientQuery.getPatientMedicalData(patientId),
        )
        if (!patientMedical.fetching && !patientMedical.error) {
          const { getDiseases } = patientDisease.data
          const { getMedicalDataByUId } = patientMedical.data
          const medNameBodyList = handleTransectionByType(
            handleDiseaseData(getDiseases, getMedicalDataByUId),
            'FLOAT',
          )
          medNameBodyList?.FLOAT?.map((item: any) => {
            if (item.diseaseAttibute?.medName === 'Height') {
              setHeightVal(item.value)
            } else if (item.diseaseAttibute?.medName === 'Weight') {
              setWeightVal(item.value)
            } else if (item.diseaseAttibute?.medName === 'BMI') {
              setBmiVal(item.value)
            } else if (
              item.diseaseAttibute?.medName === 'Systolic blood pressure (SBP)'
            ) {
              setSbpVal(item.value)
            } else if (
              item.diseaseAttibute?.medName === 'Diastolic blood pressure (DBP)'
            ) {
              setDbpVal(item.value)
            }
            //console.log(item.diseaseAttibute?.medName + ' : ' + item.value)
          })
        }
        setIsLoadedPatientMedical(true)
      }
    }*/
  }, [patientUserData.data])

  return (
    <div className="p-[40px]">
      <Grid
        container
        justifyContent="center"
        columns={{ desktop: 2.1 }}
        gap="8px"
      >
        <Grid item desktop={0.1} className=" p-[8px]" justifyContent="center">
          <Navbar
            activeMenu={route.OVERAll_PAGE_URL}
            patientId={patientId}
            cId={cId}
          />
        </Grid>
        <Grid item desktop={1.9} className="">
          <Grid
            container
            justifyContent="center"
            columns={{ desktop: 2.2 }}
            gap="8px"
          >
            <Grid
              item
              desktop={1.2}
              className=" bg-zinc-100 rounded-[20px] p-[16px] "
              justifyContent="center"
            >
              <p className="text-[22px] text-blue-main ml-2 mt-1 text-center">
                Overall Health Status
              </p>
              <Stack
                className="mt-2"
                direction={{ laptop: 'row' }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction={{ laptop: 'column' }}
                  className="flex flex-col gap-[10px]"
                >
                  <div style={{ position: 'relative' }}>
                    <SVG.BoxHeight />
                    <span
                      className="text-[28px] text-center"
                      style={{
                        position: 'absolute',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {heightVal}
                    </span>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <SVG.BoxWeight />
                    <span
                      className="text-[28px] text-center"
                      style={{
                        position: 'absolute',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {weightVal}
                    </span>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <SVG.BoxBmi />
                    <span
                      className="text-[28px] text-center"
                      style={{
                        position: 'absolute',
                        top: '70%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {bmiVal}
                    </span>
                  </div>
                </Stack>
                <div>
                  <SVG.BoxBody />
                </div>
                {!patientConsultData.fetching && !patientConsultData.error && (
                  <Stack
                    direction={{ laptop: 'column' }}
                    className="flex flex-col gap-[10px]"
                  >
                    <div style={{ position: 'relative' }}>
                      <SVG.BoxTemperature />
                      <span
                        className="text-[20px] text-center"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '70%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {patientConsultData.data.getConsultOne.temp}
                      </span>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <SVG.boxBpm />
                      <span
                        className="text-[20px] text-center "
                        style={{
                          position: 'absolute',
                          top: '60%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {patientConsultData.data.getConsultOne.hr}
                      </span>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <SVG.BoxMmhg />
                      <span
                        className="text-[20px] text-center text-green-main"
                        style={{
                          position: 'absolute',
                          top: '65%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {patientConsultData.data.getConsultOne.bp_sys === '-'
                          ? sbpVal + '/' + dbpVal
                          : patientConsultData.data.getConsultOne.bp_sys +
                            '/' +
                            patientConsultData.data.getConsultOne.bp_dia}
                      </span>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <SVG.BoxMin />
                      <span
                        className="text-[20px] text-center "
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '70%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {patientConsultData.data.getConsultOne.rr}
                      </span>
                    </div>
                  </Stack>
                )}
              </Stack>
              <div className="p-[30px]">
                <Swiper
                  slidesPerView={7}
                  spaceBetween={1}
                  observer={true}
                  observeParents={true}
                  //navigation={true}
                  modules={[Navigation, Pagination]}
                  pagination={{
                    dynamicBullets: true,
                  }}
                >
                  {categorysList.map(
                    (item: { title: string; imgName: string; url: string }) => (
                      <SwiperSlide>
                        <Link to={item.url}>
                          <ElementComponent.Image
                            fullImage={false}
                            src={item.imgName}
                          />
                        </Link>
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              </div>
            </Grid>
            <Grid item desktop={0.8}>
              <Stack
                direction={{ laptop: 'column' }}
                className=" bg-zinc-100 rounded-[20px] p-[16px]"
              >
                <p className="text-[18px] text-blue-main mt-1">ข้อมูลผู้ป่วย</p>
                {!patientUserData.fetching && !patientUserData.error ? (
                  <>
                    <Stack
                      className="mt-2"
                      direction={{ laptop: 'row' }}
                      justifyContent="space-between"
                    >
                      <Stack direction={{ laptop: 'row' }}>
                        <SVG.IcoPhp className="h-[28px] w-[28px] bg-cover bg-center" />
                        <span className="ml-4">
                          {patientUserData.data.getUser.prefix}{' '}
                          {patientUserData.data.getUser.firstname}{' '}
                          {patientUserData.data.getUser.lastname}
                        </span>
                      </Stack>
                      <Stack direction={{ laptop: 'row' }}>
                        <span className="">อายุ </span>
                        <span className="ml-4 text-blue-main">
                          {date.getAge(
                            patientUserData.data.getUser
                              .UserMedicalDemograhicData?.birthdate,
                          )}{' '}
                          ปี
                        </span>
                      </Stack>
                      <Stack direction={{ laptop: 'row' }}>
                        <span className="">HN </span>
                        <span className="ml-4 text-blue-main">
                          {patientUserData.data.getUser?.hnId}
                        </span>
                      </Stack>
                    </Stack>
                    <Grid
                      className="mt-4"
                      container
                      columns={{ desktop: 2.2 }}
                      gap="8px"
                    >
                      <Grid item desktop={0.6}>
                        <span>โรคประจำตัว</span>
                      </Grid>
                      <Grid item desktop={1.4}>
                        <span className="text-blue-main">
                          {patientUserData.data.getUser
                            .UserMedicalDemograhicData
                            ?.userUnderlyingDiseaseTrasaction?.length > 0
                            ? patientUserData.data.getUser.UserMedicalDemograhicData?.userUnderlyingDiseaseTrasaction
                                .map(
                                  (item: any) =>
                                    item.underlyingDisease.thaiName,
                                )
                                .join(',')
                            : 'ไม่มี'}
                        </span>
                      </Grid>
                      <Grid item desktop={0.6}>
                        <span>หน่วยงานที่ดูแล</span>
                      </Grid>
                      <Grid item desktop={1.4}>
                        <span className="text-blue-main">
                          {patientUserData.data.getUser
                            .EnterpriseTreatmentPlace === null
                            ? '-'
                            : patientUserData.data.getUser
                                .EnterpriseTreatmentPlace.thaiName}
                        </span>
                      </Grid>
                      <Grid item desktop={0.6}>
                        <span>แพทย์ประจำตัว</span>
                      </Grid>
                      <Grid item desktop={1.4}>
                        <span className="text-blue-main">ธนกร ยนต์นิยม</span>
                      </Grid>
                      {!patientConsultData.fetching &&
                        !patientConsultData.error && (
                          <>
                            <Grid item desktop={0.6}>
                              <span>ปรึกษาเรื่อง</span>
                            </Grid>
                            <Grid item desktop={1.4}>
                              <span className="text-blue-main">
                                {
                                  patientConsultData.data.getConsultOne
                                    .consultType.typeName
                                }
                              </span>
                            </Grid>
                            <Grid item desktop={0.6}>
                              <span>อาการเบื้องต้น</span>
                            </Grid>
                            <Grid item desktop={1.4}>
                              <span className="text-blue-main">
                                {
                                  patientConsultData.data.getConsultOne
                                    .textConsult
                                }
                              </span>
                            </Grid>
                          </>
                        )}
                      <Grid item desktop={0.6}>
                        <span>แพ้ยา/แพ้อาหาร</span>
                      </Grid>
                      <Grid item desktop={1.4}>
                        <span className="text-blue-main">
                          {
                            patientUserData.data.getUser
                              .UserMedicalDemograhicData?.allergic
                          }
                        </span>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Skeleton
                    variant="rectangular"
                    sx={{ marginTop: '25px' }}
                    height="200px"
                    width="100%"
                  />
                )}
              </Stack>
              <p className="text-[18px] text-blue-main mt-2 mb-2">
                ประวัติวินิจฉัย
              </p>
              <Stack
                direction={{ laptop: 'column' }}
                className=" bg-zinc-100 rounded-[20px] p-[16px]"
              >
                {!patientDiagnoseLastOne.fetching &&
                !patientDiagnoseLastOne.error ? (
                  <>
                    <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] text-center">
                      {`${dayjs(dateIcd).format('DD MMMM BBBB')}`}
                    </p>

                    <Grid
                      className="mt-4"
                      container
                      columns={{ desktop: 2.2 }}
                      gap="8px"
                    >
                      {patientDiagnoseLastOneData !== null &&
                        patientDiagnoseLastOneData.map((item: any) => (
                          <>
                            <Grid item desktop={0.6}>
                              <span>{item.icdCode}</span>
                            </Grid>
                            <Grid item desktop={1.4}>
                              <span>{item.icdName}</span>
                            </Grid>
                          </>
                        ))}
                    </Grid>
                    <ElementComponent.Button
                      //disable={patientUserData.fetching}
                      id="submit-diagnose-button"
                      width="w-main"
                      height="32px"
                      text="วินิจฉัยแบบเต็ม"
                      style="mt-2 ml-[70%]"
                      onAction={() => setOpenModal(true)}
                    />
                  </>
                ) : (
                  <span>ไม่มีข้อมูล</span>
                )}
              </Stack>
              <p className="text-[18px] text-blue-main mt-2 mb-2">วันนัด</p>
              {!patientConsultData.fetching && !patientConsultData.error ? (
                <Stack
                  direction={{ laptop: 'row' }}
                  className="bg-blue-main rounded-[20px]  text-white-main text-[14px]"
                >
                  <Stack
                    direction={{ laptop: 'column' }}
                    className="bg-blue-main rounded-[20px] p-[8px]  w-[100%]"
                  >
                    <Stack
                      direction={{ laptop: 'row' }}
                      justifyContent="space-between"
                    >
                      <Stack direction={{ laptop: 'row' }}>
                        <span>
                          {patientConsultData.data.getConsultOne.dateConsult}{' '}
                          {patientConsultData.data.getConsultOne.time} น.
                        </span>
                        <SVG.IcoPhp className="h-[20px] w-[20px] bg-cover bg-center ml-4" />
                        <span className="ml-4">
                          {patientConsultData.data.getConsultOne.user.firstname}{' '}
                          {patientConsultData.data.getConsultOne.user.lastname}
                        </span>
                      </Stack>
                      <Stack className="ml-1" direction={{ laptop: 'row' }}>
                        <span className="text-sky-300">
                          {user['firstname']}
                        </span>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ) : (
                <>ไม่มีข้อมูล</>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!patientDiagnoseLastOne.fetching && !patientDiagnoseLastOne.error && (
        <ModalComponent.DiagnoseModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          puId={patientId}
        />
      )}
    </div>
  )
}
export default OverAllPage
