import React from 'react'

import { ElementComponent, ChartComponent } from 'components'

interface GraphDiagnoseModalProps {
  open: boolean
  onClose: Function
  daId: string
  puId: string
  title: string
  unit: string
  showCondtion: boolean
}

export default function GraphDiagnoseModal({
  title,
  unit,
  daId,
  puId,
  showCondtion,
  open,
  onClose,
}: GraphDiagnoseModalProps) {
  return (
    <ElementComponent.ModalForm open={open} onClose={onClose}>
      <div className="w-[980px] py-[40px] px-[35px]">
        <ChartComponent.LineChartMedicalData
          daId={daId}
          puId={puId}
          thaiName={`ข้อมูลกราฟ ${title}`}
          unitThai={unit}
          enableCondition={showCondtion}
        />
      </div>
    </ElementComponent.ModalForm>
  )
}
