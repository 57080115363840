import React from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material'

interface DataTableProps {
  col: string[]
  row: any
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
})

const StyledTableRow = styled(TableRow)({
  '&.MuiTableRow-head': {
    backgroundColor: '#F1F1F1',
    borderRadius: '10px',
  },
})

const StyledTableBodyRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
})

function DataTable({ col, row }: DataTableProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow>
            {col.map(
              (topic: string, index: number) =>
                topic && (
                  <StyledTableCell align="center" key={`col-${index}`}>
                    {topic}
                  </StyledTableCell>
                ),
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {row.map(
            (dataItem, rowIndex: number) =>
              dataItem && (
                <StyledTableBodyRow key={`row-${rowIndex}`}>
                  {col.map((topic: string, dataIndex: number) => (
                    <StyledTableCell
                      align="center"
                      key={`row-data-${dataIndex}`}
                    >
                      {dataItem[topic]}
                    </StyledTableCell>
                  ))}
                </StyledTableBodyRow>
              ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DataTable
