import { Grid } from '@mui/material'
import { Navbar } from 'components/navbar'
import { route } from 'settings'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
// import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
// import styles bundle
import 'swiper/css/bundle'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useQuery } from 'urql'
import { MedicalQuery } from 'services/graphql/medicalService'
import NormalDiseaseCard from './NormalDiseaseCard'
import AssociateDiseaseCard from './AssociateDiseaseCard'
function DiseasePage() {
  const { patientId } = useParams()
  const { medIndex } = useParams()
  const { cId } = useParams()

  const categorysList: {
    index: number
    title: string
    imgName: string
    url: string
    medName: string
  }[] = [
    {
      index: 0,
      title: 'ข้อมูลผู้ป่วย',
      imgName: Pics.Item0,
      url: `${route.OVERAll_PAGE_URL}/${patientId}/${cId}`,
      medName: '',
    },
    {
      index: 1,
      title: 'เบาหวาน',
      imgName: Pics.Item1,
      url: `${route.DISEASE_PAGE_URL}/1/${patientId}/${cId}`,
      medName: 'Diabetes mellitus (DM)',
    },
    {
      index: 2,
      title: 'ความดัน',
      imgName: Pics.Item2,
      url: `${route.DISEASE_PAGE_URL}/2/${patientId}/${cId}`,
      medName: 'Hypertension',
    },
    {
      index: 3,
      title: 'ไขมัน',
      imgName: Pics.Item3,
      url: `${route.DISEASE_PAGE_URL}/3/${patientId}/${cId}`,
      medName: 'Dyslipidemia',
    },
    {
      index: 4,
      title: 'ตับ',
      imgName: Pics.Item4,
      url: `${route.DISEASE_PAGE_URL}/4/${patientId}/${cId}`,
      medName: 'Chronic Liver Disease/Cirrhosis (CLD)',
    },
    {
      index: 5,
      title: 'ไต',
      imgName: Pics.Item5,
      url: `${route.DISEASE_PAGE_URL}/5/${patientId}/${cId}`,
      medName: 'Chronic Kidney Disease (CKD)',
    },
    {
      index: 6,
      title: 'หัวใจ',
      imgName: Pics.Item6,
      url: `${route.DISEASE_PAGE_URL}/6/${patientId}/${cId}`,
      medName: '',
    },
  ]
  const [selectDisease, setSelectDisease] = useState<string>(
    categorysList[medIndex].medName,
  )
  const [diseaseTitle, setDiseaseTitle] = useState<string>(
    categorysList[medIndex].title,
  )
  const [diabetesScorce] = useQuery(
    MedicalQuery.getHealthStatusDiseaseScorcesForDoctor(
      selectDisease,
      patientId,
    ),
  )
  const [diabetesData] = useQuery(MedicalQuery.getOneDiseaseData(selectDisease))

  const [diseaseData] = useQuery(MedicalQuery.getOneDiseaseData(selectDisease))
  const mapValue = {
    '0': {
      value: 0.2,
      valueStr: 20,
      color: '#E40009',
    },
    '1': {
      value: 0.4,
      color: '#F19701',
    },
    '2': {
      value: 0.6,
      color: '#FBFF01',
    },
    '3': {
      value: 0.8,
      color: '#CDD801',
    },
    '4': {
      value: 1.0,
      color: '#69ae33',
    },
  }
  return (
    <div className="p-[40px]">
      <Grid
        container
        justifyContent="center"
        columns={{ desktop: 2.2 }}
        gap="8px"
      >
        <Grid item desktop={0.1} className=" p-[8px]" justifyContent="center">
          <Navbar
            activeMenu={route.OVERAll_PAGE_URL}
            patientId={patientId}
            cId={cId}
          />
        </Grid>
        <Grid item desktop={1.9} className="">
          <Grid
            container
            justifyContent="center"
            columns={{ desktop: 2.1 }}
            gap="8px"
          >
            <Grid item desktop={0.8} justifyContent="center">
              <div className=" bg-zinc-100 rounded-[20px] p-[16px] ">
                <p className="text-[22px] text-grey-main ml-2 mt-1 text-center">
                  {diseaseTitle}
                </p>
                <div>
                  <div className="w-[30%] ml-[35%] mt-4">
                    {!diabetesScorce.fetching &&
                    !diabetesScorce.error &&
                    diabetesScorce.data.getHealthStatusDiseaseScorcesForDoctor
                      .length > 0 ? (
                      <CircularProgressbarWithChildren
                        strokeWidth={15}
                        value={
                          mapValue[
                            diabetesScorce.data.getHealthStatusDiseaseScorcesForDoctor[0].value.toPrecision(
                              1,
                            )
                          ].value * 100
                        }
                        text=""
                        background
                        styles={buildStyles({
                          // Text size
                          textSize: '16px',

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 2,

                          // Colors
                          pathColor:
                            mapValue[
                              diabetesScorce.data.getHealthStatusDiseaseScorcesForDoctor[0].value.toPrecision(
                                1,
                              )
                            ].color,
                          textColor: '#f88',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#d6d6d6',
                        })}
                      >
                        <ElementComponent.Image
                          fullImage={true}
                          src={Pics.DiseaseHyperlipidemia}
                        />
                      </CircularProgressbarWithChildren>
                    ) : (
                      <CircularProgressbarWithChildren
                        strokeWidth={15}
                        value={0}
                        text=""
                        background
                        styles={buildStyles({
                          // Text size
                          textSize: '16px',

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 2,

                          // Colors
                          pathColor: '#CAEBB1',
                          textColor: '#f88',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#d6d6d6',
                        })}
                      >
                        <ElementComponent.Image
                          fullImage={true}
                          src={Pics.DiseaseHyperlipidemia}
                        />
                      </CircularProgressbarWithChildren>
                    )}
                  </div>
                </div>
                <p
                  className="text-[16px] w-[30%] ml-[35%] rounded-[20px] text-center mt-4"
                  style={{ color: '#CAEBB1', backgroundColor: '#FFF' }}
                >
                  {!diabetesScorce.fetching &&
                  !diabetesScorce.error &&
                  diabetesScorce.data.getHealthStatusDiseaseScorcesForDoctor
                    .length > 0
                    ? mapValue[
                        diabetesScorce.data.getHealthStatusDiseaseScorcesForDoctor[0].value.toPrecision(
                          1,
                        )
                      ].value * 100
                    : '0'}
                  %
                </p>
                <div className="p-[30px]">
                  <Swiper
                    slidesPerView={5}
                    spaceBetween={1}
                    observer={true}
                    observeParents={true}
                    //navigation={true}
                    modules={[Navigation, Pagination]}
                    pagination={{
                      dynamicBullets: true,
                    }}
                  >
                    {categorysList.map(
                      (item: {
                        index: number
                        title: string
                        imgName: string
                        medName: string
                        url: string
                      }) => (
                        <SwiperSlide
                          onClick={() => {
                            if (item.index === 0) {
                              window.location.assign(item.url)
                            }
                            setSelectDisease(item.medName)
                            setDiseaseTitle(item.title)
                          }}
                        >
                          <ElementComponent.Image
                            fullImage={false}
                            src={item.imgName}
                          />
                        </SwiperSlide>
                      ),
                    )}
                  </Swiper>
                </div>
              </div>
            </Grid>
            <Grid item desktop={1.2}>
              <Grid
                container
                justifyContent="center"
                columns={{ desktop: 2.2 }}
                gap="8px"
              >
                {/* Normal Disease */}
                {!diabetesData.fetching && !diabetesData.error ? (
                  <>
                    {/* Disease attribute */}

                    <NormalDiseaseCard
                      diseaseData={diseaseData.data.getDiseases[0]}
                      puId={patientId}
                    />
                    {/* Associate Disease */}
                    {diabetesData?.data?.getDiseases[0]?.associateDiease
                      ?.length > 0 && (
                      <AssociateDiseaseCard
                        diseaseData={
                          diabetesData.data.getDiseases[0].associateDiease
                        }
                        puId={patientId}
                      />
                    )}
                  </>
                ) : (
                  'Loading'
                )}
                {/*<Grid item desktop={1} justifyContent="center">
                  <div className=" bg-zinc-100 rounded-[20px] p-[16px] ">
                    <ChartComponent.LineChartDisease />
                  </div>
                </Grid>
                <Grid item desktop={1} justifyContent="center">
                  <div className=" bg-zinc-100 rounded-[20px] p-[16px] ">
                    <ChartComponent.LineChartDisease />
                  </div>
                </Grid>
                <Grid item desktop={1} justifyContent="center">
                  <div className=" bg-zinc-100 rounded-[20px] p-[16px] ">
                    <ChartComponent.LineChartDisease />
                  </div>
                </Grid>
                <Grid item desktop={1} justifyContent="center">
                  <div className=" bg-zinc-100 rounded-[20px] p-[16px] ">
                    <ChartComponent.LineChartDisease />
                  </div>
                </Grid>
                */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default DiseasePage
